import { styled } from "styled-components";
import { MainContainer } from "../../components/MainContainer/MainContainer";
import notebook from '../../images/notebook.png';
import bgSimple from '../../images/backgroundSimple.png'

import 'aos/dist/aos.css'
import AOS from 'aos';
import { useEffect } from "react";

const IndicatorIntroDiv = styled.div`
width:100%;
display:flex;
flex-direction:row;
justify-content:center;
align-items:center;
position:relative;
`;

const VideoContainer = styled.div`
display:flex;
justify-content:center;
`;

const VideoSource = styled.video`
width:100%;
border-radius:12px;
border:16px solid rgb(56,172,131, 0.1);
`;

const IndicatorIntroTitleDiv = styled.div`
display:flex;
justify-content: center;
flex-direction:column;
align-items:center;
gap:20px;
`;

const IndicatorIntroTitle = styled.p`
color:white;
font-weight:500;
font-size:65px;
text-align:center;
max-width:1000px;

@media screen and (max-width:960px){
    font-size:40px;
}
`;

const IndicatorIntroSubtitle = styled.p`
color:white;
font-weight:400;
font-size:20px;

@media screen and (max-width:960px){
    font-size:20px;
    text-align:center;
}
`;

const ImageContainer = styled.div`
display:flex;
justify-content:center;
`;

const ImageIntro = styled.img`
width:60%;

@media screen and (max-width:960px){
    width:100%;
}
`;

export const BgSimple = styled.img`
width:120%;
position:absolute;
top:0px;
right:0px;
z-index:-1;
opacity:100%;

@media screen and (max-width:960px){
    justify-content:center;
    display:flex;
    width:300%;
}
`;

const IndicatorIntro = () => {

    useEffect(() => {
        AOS.init({ duration: 800 });
    }, []);

    const bgObj = {
        'background': '#9BF3A4',
        'background': 'linear-gradient(to right, #9BF3A4 0%, #5CE4B4 45%, #14BAB9 100%)',
        '-webkit-background-clip': 'text',
        '-webkit-text-fill-color': 'transparent',
        'fontWeight':'700',
    }

    return (
        <IndicatorIntroDiv>
            <MainContainer style={{ justifyContent: 'center', flexDirection:'column', gap:'40px' }} >
                <IndicatorIntroTitleDiv data-aos="zoom-out">
                    <IndicatorIntroTitle><span style={bgObj}>WAYOB</span> PLATFORM IS A POWERFUL DRIVING FORCE IN THE FUTURE OF CRYPTO INVESTING</IndicatorIntroTitle>
                    <IndicatorIntroSubtitle>JOIN US NOW AND EXPERIENCE THE EXCLUSIVE ADVANTAGES TAILORED FOR YOU</IndicatorIntroSubtitle>
                </IndicatorIntroTitleDiv>
                <ImageContainer data-aos="fade-up">
                    <ImageIntro src={notebook} />
                </ImageContainer>
            </MainContainer>
            <BgSimple src={bgSimple} />
        </IndicatorIntroDiv>
    );
}

export default IndicatorIntro;