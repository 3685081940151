import FaqComponent from "../../components/FaqComponent/FaqComponent";
import { styled } from "styled-components";

const IndicatorFaqDiv = styled.div`
background:#37a881;
width:100%;
display:flex;
flex-direction:column;
justify-content:center;
align-items:center;
padding:40px;
gap:50px;
`;

const FaqComponentDiv = styled.div`
display:flex;
flex-direction:column;
gap:30px;
`;

const FaqMainTitle = styled.p`
color:white;
font-weight:800;
font-size:43px;
text-align:center;
max-width:900px;
color:white;

@media screen and (max-width:960px){
    font-size:42px;
}
`;

const IndicatorFaqSection = () => {
    return (
        <IndicatorFaqDiv>
            <FaqMainTitle>FREQUENTLY ASKED QUESTIONS</FaqMainTitle>
            <FaqComponentDiv>
                <FaqComponent faqTitle={'What do liquidation indicator display?'} faqDescription={'Our indicator represents, through a heatmap, all the liquidation zones in the market for a specific ticker across various leverages in futures contracts (5x, 10x, 25x, 100x). The yellow zones indicate areas where significant liquidations have occurred. Knowing the locations of these high-liquidation zones can provide insights into potential price rebounds and the likely direction it may take.'} />
                <FaqComponent faqTitle={'How can I access the liquidation indicator?'} faqDescription={'There are various ways to access our liquidation indicator and other services, such as our automated bots or the control center. You can access them through a monthly membership or by holding a specific amount of our native cryptocurrency in your cryptocurrency wallet.'} />
                <FaqComponent faqTitle={'What our trading bots are?'} faqDescription={'These are tools employed by our liquidation indicator in an automated manner. Leveraging the vast market data at our disposal and utilizing specific algorithms to analyze and transform this data, we are able to monitor the current market liquidation and automatically invest in rebound zones.'} />
                <FaqComponent faqTitle={'How can I access the trading bots?'} faqDescription={'Access to our trading bots, managed directly through a Telegram chat or console, can be obtained either through a monthly membership or by holding a specific amount of our native cryptocurrency in your cryptocurrency wallet.'} />
            </FaqComponentDiv>
        </IndicatorFaqDiv>
    );
}

export default IndicatorFaqSection;