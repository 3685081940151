import styled, { createGlobalStyle } from 'styled-components';
import './GlobalFont.css';

const GlobalStyle = createGlobalStyle`
*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    perspective: 1000;
    font-family: "IBM Plex Sans", sans-serif; //'Montserrat', sans-serif; 
    //background: linear-gradient(to top, #110e26, #110e26, #110e26, #10152d, #101c33, #15233e, #182645, #1d2e51) fixed;
    &::-webkit-scrollbar {
        height:10px;
        background:black;
        scrollbar-width:thin;
    }

    &::-webkit-scrollbar-thumb {
        background:rgb(19,180,151);
    }   
}

body{
    background: black;
    position:relative;
}
`;

export const MainButton = styled.button`
border-radius: 4px;
background: #2FDA90;
white-space:nowrap;
padding: 16px 32px;
color:#fff;
font-size:12px;
outline:none;
border:none;
cursor:pointer;
font-weight:700;
transition: all 0.3s ease-out;

&:hover{
    transition: all 0.3s ease-out;
    background:#3db481;
}

@media screen and (max-width:960px){
    width:70%;
    border-radius:0px;
}
`;

export default GlobalStyle;