import styled from "styled-components";

const DropdownItemDiv = styled.div`
padding:14px;
border-radius:6px;
align-items:center;
display:flex;
gap:10px;

&:hover{
    background: rgb(253,252,254, 0.2);
}

@media screen and (max-width:960px){
    width:200%;
}
`;

const DropdownTextDiv = styled.div`
display:flex;
flex-direction:column;
max-width:240px;
gap:5px;

@media screen and (max-width:960px){
    gap:0px;
}
`;

const DropdownText = styled.p`
color:white;
font-size:16px;
font-weight:400;

@media screen and (max-width:960px){
    font-size:13px;
}
`;

const DropdownDescription = styled.p`
font-size:11px;
color:rgb(194,195,199);
font-weight:500;
`;

const DropdownItem = ({text, description, icon}) => {

    const DropdownIcon = styled(icon)`
        color: rgb(17,152,154);
        font-size:24px;
    `;
    
    return (
        <DropdownItemDiv>
            <DropdownIcon />
            <DropdownTextDiv>
                <DropdownText>{text}</DropdownText>
                <DropdownDescription>{description}</DropdownDescription>
            </DropdownTextDiv>
        </DropdownItemDiv>
    )
}

export default DropdownItem;