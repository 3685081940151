import styled from "styled-components";
import { FaBars } from "react-icons/fa";
import { motion } from "framer-motion";


export const NavbarDiv = styled.div`
display:flex;
justify-content:center;
align-items:center;
width:100%;
height:120px;
position:absolute;
top:0px;
`;

export const NavbarContainer = styled(motion.div)`
justify-content:space-between;
align-items:center;
display:flex;
width:100%;
padding:50px;
padding-left:150px;
padding-right:150px;
z-index:200;

@media screen and (max-width:960px){
padding-left:50px;
padding-right:50px;  
}
`;

export const LogoDiv = styled.div`
display:flex;
gap:20px;
align-items: center;
`;

export const NavMenu = styled.ul`
display:flex;
gap:35px;
justify-content:center;
align-items:center;

@media screen and (max-width:960px){
justify-content:unset;
align-items:unset;
flex-direction:column;
position:absolute;
background:black;
border-right:1px solid #3b3d42;
left:${({ mobileClicked }) => mobileClicked == true ? '0px' : '-100%'};
width:80%;
height:100vh;
top:0px;
padding:30px;
transition:0.2s ease-in-out;
z-index:101;
position:fixed;
}
`;

export const ButtonsDiv = styled.div`
display:flex;
align-items:center;
justify-content:center;

@media screen and (max-width:960px){
    width:100%;
    display:${({ isMobile }) => isMobile ? 'flex' : 'none'};
}
`;

export const LogoText = styled.p`
color:white;
font-weight:500;
font-size:32px;
`;

export const NavItem = styled.li`
list-style:none;
font-weight:400;
font-size:16px;
`;

export const NavItemText = styled.p`
padding:10px;
cursor:pointer;
transition:0.1s;
color:#437e74;
border-radius:6px;

&:hover{
    background: rgb(253,252,254, 0.2);
    color:white;
    transition:0.1s;
 }

 &:active{
    transform:scale(0.9);
 }
`
;

export const WrapperButton = styled.div`
padding:2px;
border-radius:60px;
background: #9BF3A4;
background: linear-gradient(to right, #9BF3A4 0%, #5CE4B4 45%, #14BAB9 100%);
`;

export const AppButton = styled.button`
border:none;
border-radius:60px;
background:black;
color:white;
padding:14px;
transition: 0.2s;
font-weight:600;
cursor:pointer;
font-size:15px;

&:hover{
    background:rgb(0, 0, 0, 0.5);
    transition: 0.2s;
    color:white;
}

&:active{
    transform:scale(0.9);
 }

@media screen and (max-width:960px){
    width:100%;
}
`;

export const MobileIcon = styled(FaBars)`
color:white;
cursor:pointer;
display:none;
color:rgb(149,156,167);
font-size:20px;

@media screen and (max-width:960px){
    display:flex;
}
`;

export const LogoMobileDiv = styled.div`
font-size:24px;
display:none;
color:white;

@media screen and (max-width:960px){
    display:flex;
    padding-bottom:20px;
}
`;

export const LogoImg = styled.img`
max-width:140px;

@media screen and (max-width:960px){
    max-width:140px;
}
`;