import { motion, useAnimation, useInView } from 'framer-motion';
import { useEffect, useRef } from 'react';
import { mainColors } from '../../utils/globalObjects';

const animations = {
    hidden: { opacity: 0, y: 75 },
    visible: { opacity: 1, y: 0 },
}

const Reveal = ({ children }) => {

    return (
        <div style={{position:'relative'}}>
            <motion.div variants={animations} initial='hidden' transition={{ duration: 0.5, delay: 0.25 }} whileInView='visible' viewport={{ once: true, amount:0.5 }}>
                {children}
            </motion.div>
            <motion.div 
                variants={{
                    hidden:{left:0},
                    visible:{left:'100%'},
                }}
                initial='hidden'
                whileInView='visible'
                viewport={{ once: true, amount:0.5 }}
                transition={{duration:0.5, ease:'easeIn'}}
                style={{
                    position:'absolute',
                    top:4,
                    bottom:4,
                    left:0,
                    right:0,
                    background:mainColors.greenColor,
                    zIndex:20,
                }}
            />
        </div>
    )
}

export default Reveal;