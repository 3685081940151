import AboutIntro from "./AboutIntro";
import AboutTeam from "./AboutTeam";
import AboutPartners from "./AboutPartners";

const AboutPage = () => {
    return (
        <>
            <AboutIntro />
            <AboutTeam />
            <AboutPartners />
        </>
    );
}

export default AboutPage;