import image1 from '../../images/image1.png'
import { FaChartBar } from "react-icons/fa";
import { SiPowerautomate } from "react-icons/si";
import { FaLightbulb } from "react-icons/fa";
import { FaBrain } from "react-icons/fa";
import { GiArtificialHive } from "react-icons/gi";
import { IoIosFlash } from "react-icons/io";
import { BiSolidContact } from "react-icons/bi";
import { GiArtificialIntelligence } from "react-icons/gi";
import { IoMdAnalytics } from "react-icons/io";
import { SiBaremetrics } from "react-icons/si";
import { RiCustomerService2Line } from "react-icons/ri";
import image2 from '../../images/image2.png';
import poolVideo from '../../videos/poolVideo.webm';
import mobileVideo from '../../videos/mobileVideo.webm';
import comp1 from '../../videos/comp1.webm';
import comp2 from '../../videos/comp2.webm';

export const secondarySectionObject1 = {
    type:'row',
    bg1:'rgb(32,34,39)',
    bg2:'rgb(32,34,39)',
    title:'Liquidation Indicator',
    subtitle:'The liquidation indicator uses a heatmap to pinpoint areas with higher losses, highlighting yellow points where liquidations are concentrated. By familiarizing yourself with these zones, you can predict with high probability the future price direction.',
    image:image1,
}

export const secondarySectionObject2 = {
    type:'row-reverse',
    bg1:'rgb(55,168,129)',
    bg2:'linear-gradient(0deg, rgba(55,168,129,1) 0%, rgba(41,85,73,1) 28%, rgba(36,58,55,1) 44%, rgba(34,47,47,1) 56%, rgba(32,34,39,1) 73%)',
    title:'Historical chart',
    subtitle:'Explore historical charts for all our available coins featuring our indicators. Utilize them for backtesting your strategies and assessing the effectiveness of the indicator in predicting price movements.',
    image:image1,
}

export const platformHalfObject1 = {
    advantagesList: [
        {advantageText: 'Data and Strategies analysis.', advantageIcon: FaChartBar},
        {advantageText: 'Execution automation.', advantageIcon: SiPowerautomate},
        {advantageText: 'Creates operations based on informed decisions.', advantageIcon: FaLightbulb},
        {advantageText: 'Intuitive and powerful environment.', advantageIcon: FaBrain},
    ],
    firstWord:'strategy',
    title:'lab',
    subtitle:'It is a core component of our trading infraestucture, developed to support data analysis, explore different macro strategies, like Liquidity Zones and high-frequency.',
    rowType:'row-reverse',
    videoSource:poolVideo,
    srcWidth:'100%',
    halfType:2,
}

export const platformHalfObject2 = {
    advantagesList: [
        {advantageText: 'Decision-tree structures + advanced artificial intelligence technologies.', advantageIcon: GiArtificialHive},
        {advantageText: 'Quick, straightforward guidance.', advantageIcon: IoIosFlash},
        {advantageText: 'Tailored to your unique profile.', advantageIcon: BiSolidContact},
    ],
    firstWord:'wayob',
    title:'Autonomous Agent',
    subtitle:'Your own crypto investment assistant that provide quick access to crypto trading, designed to empower investment advisors with a wealth of technical crypto knowledge.',
    rowType:'row',
    videoSource:comp2,
    srcWidth:'60%',
    halfType:0,
}

export const platformHalfObject3 = {
    advantagesList: [
        {advantageText: 'AI-Powered.', advantageIcon: GiArtificialIntelligence},
        {advantageText: 'Real-time market news, updates, and analyses.', advantageIcon: FaChartBar},
        {advantageText: 'Insights into potential market shifts.,', advantageIcon: SiPowerautomate},
        {advantageText: 'All are based on each user profile and portfolio.,', advantageIcon: BiSolidContact},
    ],
    firstWord:'news',
    title:'Feed',
    subtitle:'News Feed keep users up to date with the latest developments, trends, and insights related to the market. It provides real-time information that flow seamlessly.',
    rowType:'row',
    videoSource:comp1,
    srcWidth:'60%',
    halfType:0,
}

export const platformHalfObject4 = {
    advantagesList: [
        {advantageText: 'Strategies portfolio.', advantageIcon: FaChartBar},
        {advantageText: 'Risk management dashboard.', advantageIcon: SiPowerautomate},
        {advantageText: 'Customer communication center.', advantageIcon: RiCustomerService2Line},
        {advantageText: 'User Engagement Metrics.', advantageIcon: SiBaremetrics},
        {advantageText: 'Performance Analytics.', advantageIcon: IoMdAnalytics},
        {advantageText: 'Strategy Tracking.', advantageIcon: FaChartBar},
    ],
    firstWord:'control',
    title:'center',
    subtitle:'Is where investment advisors manage their strategies and is a hub where users visualize and manage their investments.',
    rowType:'row-reverse',
    videoSource:image1,
    srcWidth:'60%',
    halfType:1,
}

export const platformHalfObject5 = {
    advantagesList: [
        {advantageText: 'Create your own AI ChatBot.', advantageIcon: FaChartBar},
        {advantageText: 'Train AI with your own Data.', advantageIcon: SiPowerautomate},
        {advantageText: 'Upload your own files.', advantageIcon: RiCustomerService2Line},
        {advantageText: 'Train AI with external webpage links.', advantageIcon: SiBaremetrics},
    ],
    firstWord:'customize',
    title:'your AI',
    subtitle:'Set up and customize your own artificial intelligence-powered chatbot, easily training it with external data of your choice.',
    rowType:'row-reverse',
    videoSource:image1,
    srcWidth:'60%',
    halfType:1,
}