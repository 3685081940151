import { styled } from "styled-components";
import { MainContainer } from "../../components/MainContainer/MainContainer";
import { IoMdCheckmark } from "react-icons/io";

const FeatureSectionDiv = styled.div`
width:100%;
display:flex;
flex-direction:row;
justify-content:center;
align-items:center;
`;

const FeatureTitle = styled.p`
font-size:60px;
font-weight:500;
font-weight:600;
max-width:1000px;
background: #9BF3A4;
background: linear-gradient(to right, #9BF3A4 0%, #5CE4B4 45%, #14BAB9 100%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;

@media screen and (max-width:960px){
    font-size:40px;
}
`;

const TableDiv = styled.div`
width:100%;
display:flex;
justify-content:center;
align-items:center;
overflow-x:auto;
`;

const FeatTable = styled.table`
border-collapse:collapse;
color:white;
width:90%;

@media screen and (max-width:960px){

}
`;

const FeatTr = styled.tr`
transition:0.2s;
&:hover{
    background:rgb(255, 255, 255, 0.1);
    cursor:pointer;
    transition:0.2s;
}
`;

const FeatTh = styled.th`
padding:30px;
//background:rgb(56,169,129);
max-width:100px;
font-weight:400;

@media screen and (max-width:960px){
    font-size:10px;
    padding:20px;
}
`;

const FeatTd = styled.td`
border:1px solid rgb(56,169,129);
padding:20px;
height:80px;
text-align:center;
font-weight:400;
font-size:21px;
max-width:100px;

@media screen and (max-width:960px){
    font-size:10px;
    padding:10px;
    height:50px;
}
`;

const TickIcon = styled(IoMdCheckmark)`
font-size:32px;
color:rgb(255, 255, 255, 0.4);

@media screen and (max-width:960px){
    font-size:20px;
}
`;

const FeatureSection = () => {
    const backgroundObject = {background: 'rgb(56,169,129, 0.3)'};
    const backgroundObject2 = {background: 'rgb(12,192,92)',
        background: 'linear-gradient(90deg, rgba(12,192,92,1) 0%, rgba(13,192,125,1) 31%, rgba(14,191,161,1) 63%, rgba(16,187,190,1) 100%)'};

    return (
        <FeatureSectionDiv>
            <MainContainer style={{ flexDirection: 'column', alignItems:'center', maxWidth:'1500px', width:'100%'}}>
                <FeatureTitle>FEATURES</FeatureTitle>
                <TableDiv>
                    <FeatTable>
                        <FeatTr style={backgroundObject2}>
                            <FeatTh></FeatTh>
                            <FeatTh>FREE</FeatTh>
                            <FeatTh>BASIC</FeatTh>
                            <FeatTh>PRO</FeatTh>
                            <FeatTh>ENTERPRISE</FeatTh>
                        </FeatTr>
                        <FeatTr>
                            <FeatTd style={{background:'rgb(255,255,255, 0.1)'}}>WAYOB BOT</FeatTd>
                            <FeatTd style={backgroundObject}><TickIcon /></FeatTd>
                            <FeatTd style={backgroundObject}><TickIcon /></FeatTd>
                            <FeatTd style={backgroundObject}><TickIcon /></FeatTd>
                            <FeatTd style={backgroundObject}><TickIcon /></FeatTd>
                        </FeatTr>
                        <FeatTr>
                            <FeatTd style={{background:'rgb(255,255,255, 0.1)'}}>WAYOB STRATEGIES</FeatTd>
                            <FeatTd style={backgroundObject}><TickIcon /></FeatTd>
                            <FeatTd style={backgroundObject}><TickIcon /></FeatTd>
                            <FeatTd style={backgroundObject}><TickIcon /></FeatTd>
                            <FeatTd style={backgroundObject}><TickIcon /></FeatTd>
                        </FeatTr>
                        <FeatTr>
                            <FeatTd style={{background:'rgb(255,255,255, 0.1)'}}>NEWS FEED</FeatTd>
                            <FeatTd>-</FeatTd>
                            <FeatTd style={backgroundObject}><TickIcon /></FeatTd>
                            <FeatTd style={backgroundObject}><TickIcon /></FeatTd>
                            <FeatTd style={backgroundObject}><TickIcon /></FeatTd>
                        </FeatTr>
                        <FeatTr>
                            <FeatTd style={{background:'rgb(255,255,255, 0.1)'}}>CONTROL CENTER</FeatTd>
                            <FeatTd>-</FeatTd>
                            <FeatTd>-</FeatTd>
                            <FeatTd style={backgroundObject}><TickIcon /></FeatTd>
                            <FeatTd style={backgroundObject}><TickIcon /></FeatTd>
                        </FeatTr>
                        <FeatTr>
                            <FeatTd style={{background:'rgb(255,255,255, 0.1)'}}>MULTI-TRADE STRATEGIES</FeatTd>
                            <FeatTd>-</FeatTd>
                            <FeatTd>-</FeatTd>
                            <FeatTd style={backgroundObject}><TickIcon /></FeatTd>
                            <FeatTd style={backgroundObject}><TickIcon /></FeatTd>
                        </FeatTr>
                        <FeatTr>
                            <FeatTd style={{background:'rgb(255,255,255, 0.1)'}}>CUSTOM TRADE STRATEGIES</FeatTd>
                            <FeatTd>-</FeatTd>
                            <FeatTd>-</FeatTd>
                            <FeatTd style={backgroundObject}><TickIcon /></FeatTd>
                            <FeatTd style={backgroundObject}><TickIcon /></FeatTd>
                        </FeatTr>
                        <FeatTr>
                            <FeatTd style={{background:'rgb(255,255,255, 0.1)'}}>YOUR OWN BRAND STRATEGY AND BOT</FeatTd>
                            <FeatTd>-</FeatTd>
                            <FeatTd>-</FeatTd>
                            <FeatTd>-</FeatTd>
                            <FeatTd style={backgroundObject}><TickIcon /></FeatTd>
                        </FeatTr>
                        <FeatTr>
                            <FeatTd style={{background:'rgb(255,255,255, 0.1)'}}>COPY TRADING</FeatTd>
                            <FeatTd>-</FeatTd>
                            <FeatTd>-</FeatTd>
                            <FeatTd style={backgroundObject}><TickIcon /></FeatTd>
                            <FeatTd style={backgroundObject}><TickIcon /></FeatTd>
                        </FeatTr>
                        <FeatTr style={{background:'rgb(56,169,129)'}}>
                            <FeatTd>PRICE</FeatTd>
                            <FeatTd>FREE</FeatTd>
                            <FeatTd>$14.99</FeatTd>
                            <FeatTd>$24.99</FeatTd>
                            <FeatTd><a style={{textDecoration:'none', color:'white'}} href='mailto:contact@wayoblabs.com'>CONTACT US</a></FeatTd>
                        </FeatTr>
                    </FeatTable>
                </TableDiv>
            </MainContainer>
        </FeatureSectionDiv>
    )
}

export default FeatureSection;