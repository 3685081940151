import styled from "styled-components";
import Reveal from "../AnimationComponents/Reveal";

const VideoDiv = styled.div`
width:100%;
display:flex;
justify-content:center;
align-items:center;
padding:60px;
background:black;
z-index:100;
position:relative;
`;

const VideoContainer = styled.div`
padding-top: 56.25%;
height:90%;
width:90%;
position:relative;
overflow:hidden;

@media screen and (max-width:960px){
    height:100%;
    width:100%;
}
`;

const VideoSource = styled.iframe`
position: absolute;
top: 0;
left: 0;
bottom: 0;
right: 0;
width: 100%;
height: 90%;
border:14px solid rgb(77,234,208, 0.6);
border-radius: 1px 80px;

@media screen and (max-width:960px){
    height:100%;
}
`;

const MainVideoComponent = () => {
    return (
        <Reveal>
            <VideoDiv>
                <VideoContainer>
                    <VideoSource src="https://www.youtube.com/embed/rsFBcFk-3Ww"></VideoSource>
                </VideoContainer>
            </VideoDiv>
        </Reveal>
    );
};

export default MainVideoComponent;