import styled from "styled-components";
import panther from '../../images/panther.png';
import { mainColors } from "../../utils/globalObjects";
import * as Chat from "./ChatStyle";
import { useState, useRef } from "react";
import { postBotMessage } from "./mainChatBotFunctions";

const MainButton = styled.button`
display:flex;
justify-content:start;
width:80px;
background: rgb(19,179,151);
background: linear-gradient(90deg, rgba(19,179,151,1) 0%, rgba(15,163,143,1) 24%, rgba(13,151,138,1) 49%, rgba(10,137,132,1) 78%, rgba(8,131,129,1) 100%);
border-radius:100%;
border:2px solid ${mainColors.greenColor};
cursor:pointer;
transition:0.3s;
padding:10px;
z-index:900;

&:hover{
    transform:scale(1.1);
}

&:active{
    transform:scale(0.95);
    transition:0.3s;
}
`

const PantherLogo = styled.img`
width:100%;
opacity:30%;
border-radius:40%;
`;

const MainButtonDiv = styled.div`
display:flex;
flex-direction:row;
justify-content:end;
position:fixed;
bottom:0px;
right:0px;
margin:10px;
z-index:900;
gap:20px;
align-items:center;
`;

const TryLabelDiv = styled.div`
padding:10px;
background: rgb(19,179,151);
background: linear-gradient(90deg, rgba(19,179,151,1) 0%, rgba(15,163,143,1) 24%, rgba(13,151,138,1) 49%, rgba(10,137,132,1) 78%, rgba(8,131,129,1) 100%);
border-radius:6px;
cursor:pointer;
font-weight:500;
transition:0.2s;

&:hover{
    transform:scale(1.04);
    transition:0.2s;
}
`;

const MainChatBot = () => {
    const [showChat, setShowChat] = useState(false);
    const [userInput, setUserInput] = useState('');
    const [chatMsg, setChatMsg] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [modeState, setModeState] = useState({ mode: 'basic', clicked: false });

    const chatBodyRef = useRef(null);

    const handleModeState = (key, value) => {
        setModeState((prev) => {
            return { ...prev, [key]: value }
        })
    }

    const handleSend = async (event) => {
        if (event != undefined && event.key != 'Enter') return;
        if (isLoading == true) return;

        setChatMsg((prev) => {
            return [...prev, { type: 'user', msg: userInput }];
        })
        setUserInput('');

        setIsLoading(true);
        const resp = await postBotMessage(userInput, modeState.mode);
        if (resp.status != 200) return;

        setChatMsg((prev) => {
            return [...prev, { type: 'bot', msg: resp.response.response }];
        })

        chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
        setIsLoading(false);
    }

    return (
        <>
            <MainButtonDiv>
                <TryLabelDiv onClick={() => setShowChat(true)}>TRY WAYOB AI</TryLabelDiv>
                <MainButton onClick={() => setShowChat(true)}><PantherLogo src={panther} /></MainButton>
            </MainButtonDiv>
            <Chat.ChatContainer showChat={showChat}>
                <Chat.ChatModeDiv show={modeState.clicked} onClick={() => handleModeState('clicked', false)}>
                    <Chat.ChatModeItem onClick={() => handleModeState('mode', 'basic')}>/demo</Chat.ChatModeItem>
                </Chat.ChatModeDiv>
                <Chat.ChatHeader>
                    <Chat.ChatTitleDiv>
                        <Chat.ChatTitle>WAYOB AI</Chat.ChatTitle>
                        <Chat.CloseButton onClick={() => setShowChat(false)} />
                    </Chat.ChatTitleDiv>
                </Chat.ChatHeader>
                <Chat.ChatBody ref={chatBodyRef}>
                    <Chat.ChatMsgDiv>
                        <Chat.ChatBotMsg >
                            Hello,
                            <br /><br />
                            Welcome to our <strong>demo</strong>! We are thrilled to have you here to try out what we have been working so hard on. We hope you enjoy exploring all the features and functionalities we have developed.
                            <br /><br />
                            We would like to invite you to follow us on our social media channels to stay updated with all the news and updates. 
                            <br /><br />
                            Additionally, if you follow us, you can get a <strong>free entry to the exclusive beta</strong>. Don’t miss this opportunity to be one of the first to experience all the new things we have prepared.
                            <br /><br />
                            Follow us on:
                            <br /><br />
                            Telegram: <a style={{color:'#0ce6be'}} href="https://t.me/wayoblabspublic">https://t.me/wayoblabspublic</a>
                            <br/>
                            Twitter: <a style={{color:'#0ce6be'}} href="https://x.com/wayoblabs">https://x.com/wayoblabs</a>
                            <br/>
                            <br />
                            Once you follow us, send us a direct message on any of our social media channels <strong>to receive your free beta access</strong>.  
                            <br /><br />
                            Feel free to ask us any questions about what Wayob AI does and how it can help you. We're here to assist you with anything you need.
                            <br /><br />
                            Thank you for joining us on this exciting journey. We look forward to your feedback and suggestions to keep improving!
                            <br/><br/>
                            Best regards,
                            <br />
                            Wayob Labs Team
                        </Chat.ChatBotMsg>
                    </Chat.ChatMsgDiv>
                    {
                        chatMsg.map((msg) => {
                            return (<>
                                {
                                    msg.type === 'user' ?
                                        <Chat.ChatMsgDiv style={{ justifyContent: 'end' }}>
                                            <Chat.ChatUserMsg>{msg.msg}</Chat.ChatUserMsg>
                                        </Chat.ChatMsgDiv>
                                        :
                                        <Chat.ChatMsgDiv>
                                            <Chat.ChatBotMsg>{msg.msg}</Chat.ChatBotMsg>
                                        </Chat.ChatMsgDiv>
                                }
                            </>
                            )
                        })
                    }
                    {
                        isLoading &&
                        <Chat.ChatMsgDiv>
                            <Chat.ChatBotMsg style={{ fontSize: '24px', letterSpacing: '4px' }}>...</Chat.ChatBotMsg>
                        </Chat.ChatMsgDiv>
                    }
                </Chat.ChatBody>
                <Chat.ChatSend>
                    <Chat.ChatModeButton onClick={() => handleModeState('clicked', !modeState.clicked)}>/demo</Chat.ChatModeButton>
                    <Chat.ChatInput onKeyDown={handleSend} type='text' value={userInput} onChange={(e) => setUserInput(e.target.value)} />
                    <Chat.ChatSendButton onClick={() => handleSend()}>SEND</Chat.ChatSendButton>
                </Chat.ChatSend>
            </Chat.ChatContainer>
        </>
    );
}

export default MainChatBot;