import {styled, keyframes} from 'styled-components';
import { MainContainer } from '../../components/MainContainer/MainContainer';
import 'aos/dist/aos.css'
import AOS from 'aos';
import { useEffect } from 'react';

const BorderAnimation = keyframes`
0% {
    background-position: 0% 50%;
}
50% {
    background-position: 100% 50%;
}
100% {
    background-position: 0% 50%;
}
`;

const PlatformHalfSecDiv = styled.div`
width:100%;
display:flex;
flex-direction:row;
justify-content:center;
align-items:center;
`;

const PlatformHalfDiv = styled.div`
display:flex;
width:50%;

@media screen and (max-width:960px){
    width:100%;
}
`;

const PlatformHalfTextDiv = styled.div`
display:flex;
flex-direction:column;
gap:40px;
`;

const PlatformHalfTitleDiv = styled.div`
display:flex;
flex-direction:column;
gap:25px;
`;

const PlatformHalfTitle = styled.p`
color:white;
font-size:65px;
font-weight:500;

@media screen and (max-width:960px){
    font-size:40px;
}
`;

const PlatformHalfSubtitle = styled.p`
font-size:20px;
font-weight:400;
color:white;

@media screen and (max-width:960px){
    font-size:20px;
}
`;

const PlatformAdvantagesList = styled.ul`
display:flex;
flex-direction:column;
gap:30px;
`;

const PlatformAdvantagesItem = styled.li`
display:flex;
align-items:center;
gap:20px;
`;

const PlatformAdvantageItemText = styled.p`
color:white;
font-size:18px;
`;

const VideoContainer = styled.div`
display:flex;
justify-content:center;
position:relative;
transform-style:preserve-3d;
border-radius:12px;
--borderWidth: 6px;
position: relative;
border-radius: var(--borderWidth);

&:after{
    content: '';
    position: absolute;
    //top: calc(-1 * var(--borderWidth));
    top:5%;
    //left: calc(-1 * var(--borderWidth));
    height: calc(87% + var(--borderWidth) * 2);
    width: calc(87% + var(--borderWidth) * 2);
    background: linear-gradient(60deg, #a166ab, #5073b8, #1098ad, #07b39b, #6fba82);
    border-radius: calc(2 * var(--borderWidth));
    z-index: -1;
    animation: ${BorderAnimation} 4s ease alternate infinite;
    background-size: 300% 300%;
}
`;

const VideoSource = styled.video`
width:${({ srcWidth }) => srcWidth};
//border-radius:12px;
//border:16px solid rgb(56,172,131, 0.1);
`;

const PlatformBaseIcon = styled.div`
font-size:40px;
background:rgb(56,172,131);
color:white;
padding:10px;
border-radius:12px;
`;

const HalfImageContainer = styled.div`
display:flex;
justify-content:center;
position:relative;
--borderWidth: 6px;
position: relative;
border-radius: var(--borderWidth);
background:#0f0f0f;

&:after{
    content: '';
    position: absolute;
    top: calc(-1 * var(--borderWidth));
    left: calc(-1 * var(--borderWidth));
    height: calc(100% + var(--borderWidth) * 2);
    width: calc(100% + var(--borderWidth) * 2);
    background: linear-gradient(60deg, #a166ab, #5073b8, #1098ad, #07b39b, #6fba82);
    border-radius: calc(2 * var(--borderWidth));
    z-index: -1;
    animation: ${BorderAnimation} 3s ease alternate infinite;
    background-size: 300% 300%;
}
`;

const HalfImage = styled.img`
width:100%;
`;

const PlatformHalfSec = ({ advantagesList, firstWord, title, subtitle, rowType, videoSource, srcWidth, halfType }) => {

    useEffect(() => {
        AOS.init({ duration: 800 });
    }, []);

    const bgObj = {
        'background': '#9BF3A4',
        'background': 'linear-gradient(to right, #9BF3A4 0%, #5CE4B4 45%, #14BAB9 100%)',
        '-webkit-background-clip': 'text',
        '-webkit-text-fill-color': 'transparent',
        'fontWeight':'700',
    }

    return (
        <PlatformHalfSecDiv>
            <MainContainer type={rowType} style={{ alignItems: 'start', maxWidth: '1500px', justifyContent: 'center' }}>
                <PlatformHalfDiv data-aos="fade-up">
                    {halfType == 0 ?
                        <VideoContainer>
                            <VideoSource srcWidth={srcWidth} autoPlay muted loop>
                                <source src={videoSource} type='video/webm' autoplay />
                            </VideoSource>
                        </VideoContainer>
                        : halfType == 1 ?
                        <HalfImageContainer>
                            <HalfImage src={videoSource} />
                        </HalfImageContainer>
                        :
                        <HalfImageContainer>
                            <VideoSource style={{borderRadius:'6px'}} srcWidth={srcWidth} autoPlay muted loop>
                                <source src={videoSource} type='video/webm' autoplay />
                            </VideoSource>
                        </HalfImageContainer>
                }
                </PlatformHalfDiv>
                <PlatformHalfDiv data-aos="zoom-out">
                    <PlatformHalfTextDiv>
                        <PlatformHalfTitleDiv>
                            <PlatformHalfTitle><span style={bgObj}>{firstWord.toUpperCase()}</span> {title.toUpperCase()}</PlatformHalfTitle>
                            <PlatformHalfSubtitle>{subtitle.toUpperCase()}</PlatformHalfSubtitle>
                        </PlatformHalfTitleDiv>
                        <PlatformAdvantagesList>
                            {
                                advantagesList.map((advantageItem) => (
                                    <PlatformAdvantagesItem>
                                        <PlatformBaseIcon as={advantageItem.advantageIcon} />
                                        <PlatformAdvantageItemText>{advantageItem.advantageText}</PlatformAdvantageItemText>
                                    </PlatformAdvantagesItem>
                                ))
                            }
                        </PlatformAdvantagesList>
                    </PlatformHalfTextDiv>
                </PlatformHalfDiv>
            </MainContainer>
        </PlatformHalfSecDiv>
    );
}

export default PlatformHalfSec;