import { MainContainer } from "../MainContainer/MainContainer";
import styled from "styled-components";
import { useState } from "react";
import { MdEmail } from "react-icons/md";
import { IoDocumentTextOutline } from "react-icons/io5";
import whitepaper from '../../documents/whitepaper.pdf'
import Reveal from "../AnimationComponents/Reveal";

const ContactSectionDiv = styled.div`
display:flex;
justify-content:center;
width:100%;
`;

const ContactBoxDiv = styled.div`
display:flex;
width:100%;
gap:10px;

@media screen and (max-width:960px){
    flex-wrap:wrap;
    flex-direction:column;
}
`;

const ContactBox = styled.div`
width:100%;
max-height:700px;
gap:50px;
cursor:pointer;
border-radius:6px;
transition:0.3s;
padding:20px;
justify-content:center;
display:flex;
flex-direction:column;
background:rgb(0,23,23);

&:active{
    transform:scale(0.96);
}

&:hover{
    background: rgb(16,19,18);
    transition:0.3s;
}
`;

const ContactBoxText = styled.p`
color:white;
font-size:40px;

@media screen and (max-width:960px){
    font-size:35px;
}
`;

const ContactLogo = styled(MdEmail)`
color:white;
font-size:100px;

@media screen and (max-width:960px){
    font-size:80px;
}
`;

const DocumentLogo = styled(IoDocumentTextOutline)`
color:white;
font-size:100px;

@media screen and (max-width:960px){
    font-size:80px;
}
`;

const ContactSection = () => {
    const [userInput, setUserInput] = useState(['', '', '']);

    const changeUserInput = (value, inputId) => {
        let newUserInput = [...userInput];

        newUserInput[inputId] = value;
        setUserInput(newUserInput);
    }

    return (
        <ContactSectionDiv>
            <MainContainer style={{ flexDirection: 'column', gap: '60px', maxWidth: '100%', width:'100%', alignItems: 'center' }}>
                <ContactBoxDiv>
                <a style={{ textDecoration: 'none', width:'100%', display:'flex' }} href='mailto:contact@wayoblabs.com' >
                        <ContactBox>
                            <ContactLogo />
                            <ContactBoxText>CONTACT US</ContactBoxText>
                        </ContactBox>
                    </a>
                    <a style={{ textDecoration: 'none', width:'100%', display:'flex' }} href={whitepaper} target="_blank">
                        <ContactBox>
                            <DocumentLogo />
                            <ContactBoxText>DOCUMENTATION</ContactBoxText>
                        </ContactBox>
                    </a>
                </ContactBoxDiv>
            </MainContainer>
        </ContactSectionDiv>
    )
}

export default ContactSection;