import styled from "styled-components";

export const MainContainer = styled.div`
max-width:90%;
display:flex;
justify-content: start;
padding:40px;
padding-top:80px;
padding-bottom:80px;
gap:100px;
flex-direction:${({type}) => type != undefined ? type : 'row'};

@media screen and (max-width:960px){
    flex-direction:column;
    justify-content:center;
    align-items:center;
    max-width:100%;
    gap:30px;
    padding-top:40px;
    padding-bottom:70px;
    padding-left:20px;
    padding-right:20px;
}
`;