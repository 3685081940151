import IndicatorIntro from "./IndicatorIntro.js.js";
import SecondarySection from "../../components/SecondarySection/SecondarySection.js";
import PredictSection from "./PredictSection.js";
import IndicatorFaqSection from "./IndicatorFaqSection.js";
import PlatformHalfSec from "./PlatformHalfSec.js";
import { secondarySectionObject1, secondarySectionObject2, platformHalfObject1, platformHalfObject2, platformHalfObject3, platformHalfObject4, platformHalfObject5 } from "./indicatorPageObjects.js";

const IndicatorPage = () => {
    return (
        <>
            <IndicatorIntro />
            <PlatformHalfSec {...platformHalfObject1} />
            <PlatformHalfSec {...platformHalfObject2} />
            <PlatformHalfSec {...platformHalfObject5} />
            <PlatformHalfSec {...platformHalfObject3} />
            <PlatformHalfSec {...platformHalfObject4} />
        </>
    );
}

export default IndicatorPage;

/*
<SecondarySection {...secondarySectionObject1} />
            <PredictSection />
            <SecondarySection {...secondarySectionObject2} />
*/