import { styled, keyframes } from "styled-components";
import { MainContainer } from "../../components/MainContainer/MainContainer";
import notebook from '../../images/notebook.png'

import 'aos/dist/aos.css'
import AOS from 'aos';
import { useEffect } from "react";


const AboutIntroDiv = styled.div`
width:100%;
display:flex;
flex-direction:row;
justify-content:center;
align-items:center;
`;

const AboutTitleDiv = styled.div`
max-width:900px;
gap:30px;
display:flex;
flex-direction:column;
justify-content:center;
z-index:101;

@media screen and (max-width:960px){
    max-width:100%;
}
`;

const AboutTitleText = styled.p`
color:white;
font-weight:500;
font-size:60px;

@media screen and (max-width:960px){
    font-size:40px;
}
`;

const AboutSubtitleText = styled.p`
font-size:20px;
font-weight:400;
color:white;

@media screen and (max-width:960px){
    font-size:20px;
}
`;

const AboutImg = styled.img`
width:50%;
display:flex;

@media screen and (max-width:960px){
    width:100%;
}
`;

const PricingIntro = () => {

    useEffect(() => {
        AOS.init({duration: 800});
    }, []);

    const bgObj = {
        'background': '#9BF3A4',
        'background': 'linear-gradient(to right, #9BF3A4 0%, #5CE4B4 45%, #14BAB9 100%)',
        '-webkit-background-clip': 'text',
        '-webkit-text-fill-color': 'transparent',
    }

    return (
        <AboutIntroDiv>
            <MainContainer style={{maxWidth:'1500px', justifyContent:'space-between', gap:'60px'}}>
                <AboutTitleDiv data-aos="zoom-out">
                    <AboutTitleText><span style={bgObj}>SIGN IN</span> TODAY AND TRADE CRYPTO WITH OUR REVOLUTIONARY PLATAFORM</AboutTitleText>
                    <AboutSubtitleText>OUR PLATFORM SIMPLIFIES THE PROCESS, PROVIDES UNMATCHED SUPPORT, AND ENHANCES ENGAGEMENT TO MAXIMIZE YOUR INVESTMENT POTENTIAL.</AboutSubtitleText>
                </AboutTitleDiv>
                <AboutImg data-aos="fade-up" src={notebook}/>
            </MainContainer>
        </AboutIntroDiv>
    );
}

export default PricingIntro;