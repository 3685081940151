import PricingIntro from "./PricingIntro";
import CardSection from "./CardSection";
import FeatureSection from "./FeautureSection";

const PricingPage = () => {
    return (
        <>
            <PricingIntro />
            <CardSection />
            <FeatureSection />
        </>
    )
}

export default PricingPage;