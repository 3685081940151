import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './pages/HomePage/HomePage';
import AboutPage from './pages/AboutPage/AboutPage';
import PricingPage from './pages/PricingPage/PricingPage';
import IndicatorPage from './pages/IndicatorPage/IndicatorPage';
import Navbar from './components/Navbar/Navbar';
import GlobalStyle from './globalStyle';
import Footer from './components/Footer/Footer';
import MainChatBot from './components/MainChatBot/MainChatBot';

const App = () => {
  return (
    <Router>
      <GlobalStyle />
      <Navbar />
      <Routes>
        <Route path="/" exact element={<HomePage />} />
        <Route path="/about" exact element={<AboutPage />} />
        <Route path="/pricing" exact element={<PricingPage />} />
        <Route path="/indicator" exact element={<IndicatorPage />} />
      </Routes>
      <MainChatBot />
      <Footer />
    </Router>
  )
}

export default App;
