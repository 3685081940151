import CountdownComponent from "./CountdownComponent";
import { MainContainer } from "../MainContainer/MainContainer";
import styled from "styled-components";
import Reveal from "../AnimationComponents/Reveal";

const IntroButton = styled.button`
padding:30px;
max-width:600px;
background:#4DEAD0;
border-radius:60px;
border:none;
color:black;
font-size:24px;
font-weight:600;
cursor:pointer;
transition:0.2s;

&:active{
    transform:scale(0.93);
 }

&:hover{
    background:rgb(253,252,254, 0.1);
    transition: 0.2s;
    color:white;
}

@media screen and (max-width:960px){
    width:100%;
    font-size:14px;
}
`;

const CountdownSection = () => {
    return (
        <MainContainer style={{ maxWidth: '100%', width: '100%', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', gap: '50px' }}>
            <Reveal>
                <a target='_blank' href='https://t.me/wayob_ai_bot' style={{ textDecoration: 'none', color: 'black' }}><IntroButton>JOIN US AND ACCESS BETA FOR FREE</IntroButton></a>
            </Reveal>
            <Reveal>
                <CountdownComponent />
            </Reveal>
        </MainContainer>

    )
};

export default CountdownSection;