import { styled, keyframes } from "styled-components";
import { MainContainer } from "../../components/MainContainer/MainContainer";
import logoCircle from '../../images/logo-circle.png'
import Reveal from "../../components/AnimationComponents/Reveal";

const logoAnimation = keyframes`
0% {
    transform:translateY(0px);
  }
50% {
    transform:translateY(10px);
  }
100% {
    transform:translateY(0px);
}
`;

const AboutIntroDiv = styled.div`
width:100%;
display:flex;
flex-direction:row;
justify-content:center;
align-items:center;
padding-top:100px;
`;

const AboutTitleDiv = styled.div`
max-width:1200px;
gap:30px;
display:flex;
flex-direction:column;
justify-content:center;
z-index:101;

@media screen and (max-width:960px){
    max-width:100%;
}
`;

const AboutTitleText = styled.p`
color:white;
font-weight:500;
font-size:65px;

@media screen and (max-width:960px){
    font-size:40px;
}
`;

const AboutSubtitleText = styled.p`
font-size:20px;
font-weight:400;
color:white;

@media screen and (max-width:960px){
    font-size:20px;
}
`;

const AboutImg = styled.img`
width:75%;
animation-name: ${logoAnimation};
animation-duration: 2s;
animation-iteration-count: infinite;
transform-origin:center center;
display:flex;
animation-timing-function: ease-in-out;
animation-fill-mode: forwards;

@media screen and (max-width:960px){
}
`;

const AboutIntro = () => {

    const bgObj = {
        'background': '#9BF3A4',
        'background': 'linear-gradient(to right, #9BF3A4 0%, #5CE4B4 45%, #14BAB9 100%)',
        '-webkit-background-clip': 'text',
        '-webkit-text-fill-color': 'transparent',
        'fontWeight': '700',
    }

    return (
        <AboutIntroDiv>
            <MainContainer style={{ justifyContent: 'space-between', gap: '60px' }}>
                <AboutTitleDiv>
                    <Reveal>
                        <AboutTitleText><span style={bgObj}>SIGN IN</span> TODAY AND TRADE CRYPTO WITH OUR REVOLUTIONARY PLATFORM</AboutTitleText>
                    </Reveal>
                    <Reveal>
                        <AboutSubtitleText>OUR PLATFORM SIMPLIFIES THE PROCESS, PROVIDES UNMATCHED SUPPORT, AND ENHANCES ENGAGEMENT TO MAXIMIZE YOUR INVESTMENT POTENTIAL</AboutSubtitleText>
                    </Reveal>
                </AboutTitleDiv>
                <AboutImg src={logoCircle} />
            </MainContainer>
        </AboutIntroDiv>
    );
}

export default AboutIntro;