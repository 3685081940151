import { styled, keyframes } from "styled-components";
import { IoChevronDownSharp } from "react-icons/io5";
import { useState } from "react";

const animationIn = keyframes`
0%{
    max-height:0px;
}
100%{
    max-height:80px;
}
`;

const animationOut = keyframes`
0%{
    max-height:80px;
}
100%{
    max-height:0px;
}
`;

const FaqComponentDiv = styled.div`
display:flex;
justify-content:start;
width:100%;
max-width:1200px;
padding:20px;
border-bottom:1px solid white;
cursor:pointer;
flex-direction:column;
`;

const FaqArrow = styled(IoChevronDownSharp)`
color:white;
font-size:24px;
transform:${({clicked}) => clicked ? 'rotate(180deg)' : 'rotate(0deg)'};
transition:transform 0.5s;
`;

const FaqTitle = styled.p`
color:white;
font-size:24px;
`;

const FaqTitleDiv = styled.div`
display:flex;
width:100%;
justify-content:space-between;
`;

const FaqDescriptionDiv = styled.div`
overflow:hidden;
color:white;
background:rgb(31,205,143, 0.8);
padding:0px;
align-items:center;
max-height:${({clicked}) => clicked ? 'auto' : '0px'};
`;

const FaqDescription = styled.p`
padding:20px;
font-size:17px;
font-weight:500;
overflow:hidden;
`;

const FaqComponent = ({faqTitle, faqDescription}) => {
    const [faqClicked, setFaqClicked] = useState(false);

    return (
        <FaqComponentDiv>
            <FaqTitleDiv onClick={ () => setFaqClicked(!faqClicked) } >
                <FaqTitle>{faqTitle}</FaqTitle>
                <FaqArrow clicked={faqClicked}/>
            </FaqTitleDiv>
            <FaqDescriptionDiv clicked={faqClicked}>
                <FaqDescription clicked={faqClicked} >{faqDescription}</FaqDescription>
            </FaqDescriptionDiv>
        </FaqComponentDiv>
    )
}

export default FaqComponent;