import { styled } from "styled-components";
import { MainContainer } from "../../components/MainContainer/MainContainer";
import { FaTelegramPlane } from "react-icons/fa";
import { IoDiamond } from "react-icons/io5";
import { FaPlane } from "react-icons/fa";
import { FaCheck } from "react-icons/fa";
import Switch from "react-switch";
import { useState } from "react";
import { FaExternalLinkAlt } from "react-icons/fa";

const CardSectionDiv = styled.div`
width:100%;
display:flex;
flex-direction:row;
justify-content:center;
align-items:center;
`;

const CardSectionTitleDiv = styled.div`
width:100%;
display:flex;
flex-direction:column;
gap:10px;

@media screen and (max-width:960px){
max-width:100%; 
}
`;

const CardSectionTitle = styled.p`
font-size:60px;
font-weight:500;
background: #9BF3A4;
background: linear-gradient(to right, #9BF3A4 0%, #5CE4B4 45%, #14BAB9 100%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;

@media screen and (max-width:960px){
    font-size:40px;
}
`;

const CardSectionSubtitle = styled.p`
font-size:20px;
font-weight:400;
color:white;
max-width:1000px;

@media screen and (max-width:960px){
    font-size:20px;   
}
`;

const CardItemDiv = styled.div`
display:flex;
flex-wrap:wrap;
gap:40px;
justify-content:center;
align-items:center;

@media screen and (max-width:960px){
}
`;
//background:rgb(56,59,67, 0.5);

const CardItem = styled.div`
width:300px;
height:550px;
background:rgb(56,59,67, 0.5);
border-radius:12px;
padding:40px 20px 20px 20px;
gap:40px;
display:flex;
flex-direction:column;
position:relative;
`;

const CardButtonDiv = styled.div`
display:flex;
align-items:center;
flex-direction:column;
gap:20px;
`;

const CardLevel = styled.p`
font-size:22px;
font-weight:600;

`;

const CardDescription = styled.p`
text-align:center;
color:white;
`;

const CardButton = styled.button`
padding:14px;
font-size:18px;
font-weight:600;
border-radius:12px;
cursor:pointer;
//background:rgb(42,79,80);
background:transparent;
color:rgb(19,180,151);
border:1px solid rgb(19,180,151);
transition:0.4s;

&:hover{
    transform:translateY(4px);
    transition:0.4s;
    background:rgb(38,96,92);
}
`;

const PriceDiv = styled.div`
display:flex;
flex-direction:column;
align-items:center;
`;

const PriceText = styled.p`
font-weight:700;
font-size:42px;
color:white;
background: #9BF3A4;
background: linear-gradient(to right, #9BF3A4 0%, #5CE4B4 45%, #14BAB9 100%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
`;

const PriceDescription = styled.p`
color:rgb(143,146,142);
max-width:120px;
text-align:center;
`;

const AdvantagesDiv = styled.div`
width:100%;
display:flex;
flex-direction:column;
gap:20px;
`;

const AdvantagesText = styled.div`
color:white;
`;

const AdvantagesList = styled.div`
display:flex;
flex-direction:column;
width:100%;
justify-content:start;
margin-left:20px;
gap:15px;
`;

const AdvantageItem = styled.div`
display:flex;
gap:10px;
align-items:center;
`;

const TickIcon = styled(FaCheck)`
color:rgb(33,191,150);
`;

const AdvantageItemText = styled.p`
color:rgb(143,146,142);
max-width:200px;
`;

const SwitchDiv = styled.div`
display:flex;
justify-content:center;
gap:30px;
align-items:center;
`;

const SwitchText = styled.p`
font-size:18px;
color:white;
color:${({switchState, type}) => switchState == type ? 'white' : 'rgb(100,102,103)'};
`;

const CardLabel = styled.p`
position:absolute;
top:10px;
right:10px;
color:rgb(99,230,178);
background:rgb(99,230,178, 0.4);
padding:5px;
border-radius:9px;
font-size:14px;
font-weight:500;
`;

const ContactButton = styled.div`
display:flex;
gap:5px;
align-items:center;
cursor:pointer;
transition:0.4s;

&:hover{
    transform:translateY(4px);
    transition:0.4s;
}

`;

const ContactLogo = styled(FaExternalLinkAlt)`
font-size:16px;
color:rgb(23,150,186);
`;

const ContactText = styled.p`
font-size:26px;
font-weight:600;
color:rgb(23,150,186);
`;

const CardSection = () => {

    const [switchState, setSwitchState] = useState(false);

    return (
        <CardSectionDiv>
            <MainContainer style={{ flexDirection: 'column', justifyContent: 'start', gap: '60px', maxWidth: '1500px', width: '100%' }}>
                <CardSectionTitleDiv>
                    <CardSectionTitle>PRICING</CardSectionTitle>
                    <CardSectionSubtitle>USE OUR SERVICES NOW AND ENJOY THE ADVANTAGES THAT THE TOOLS OFFERS. IT'S YOUR TIME TO MAKE A DIFFERENCE WHEN TRADING.</CardSectionSubtitle>
                </CardSectionTitleDiv>
                <SwitchDiv>
                    <SwitchText switchState={switchState} type={false} >MONTHLY</SwitchText>
                    <Switch onChange={() => setSwitchState(!switchState)} checked={switchState} uncheckedIcon={false} checkedIcon={false} onColor="#86d3ff"/>
                    <SwitchText switchState={switchState} type={true} >ANNUAL</SwitchText>
                </SwitchDiv>
                <CardItemDiv>
                    <CardItem>
                        <CardButtonDiv>
                            <CardLevel style={{color:'white'}}>FREE</CardLevel>
                            <CardDescription>For developers that are just getting started</CardDescription>
                            <CardButton>Get started</CardButton>
                        </CardButtonDiv>
                        <PriceDiv>
                            <PriceText>Free</PriceText>
                            <PriceDescription>to get started</PriceDescription>
                        </PriceDiv>
                        <AdvantagesDiv>
                            <AdvantagesText>Free access to:</AdvantagesText>
                            <AdvantagesList>
                                <AdvantageItem>
                                    <TickIcon />
                                    <AdvantageItemText>Wayob Bot</AdvantageItemText>
                                </AdvantageItem>
                                <AdvantageItem>
                                    <TickIcon />
                                    <AdvantageItemText>Wayob Strategies</AdvantageItemText>
                                </AdvantageItem>
                            </AdvantagesList>
                        </AdvantagesDiv>
                    </CardItem>
                    <CardItem style={{ border: '1px solid rgb(99,230,178)' }}>
                        {switchState && <CardLabel>$60 SAVED</CardLabel>}
                        <CardButtonDiv>
                            <CardLevel style={{color:'rgb(99,230,178)'}}>BASIC</CardLevel>
                            <CardDescription>For developers that are just getting started</CardDescription>
                            <CardButton style={{ color: 'rgb(99,230,178)', border: '1px solid rgb(99,230,178)' }} >Get started</CardButton>
                        </CardButtonDiv>
                        <PriceDiv>
                            <PriceText>{ switchState ? '$9.99' : '$14.99'}</PriceText>
                            <PriceDescription>{ switchState ? 'per month, billed annually' : 'per month'}</PriceDescription>
                        </PriceDiv>
                        <AdvantagesDiv>
                            <AdvantagesText>Everything in FREE plus:</AdvantagesText>
                            <AdvantagesList>
                                <AdvantageItem>
                                    <TickIcon />
                                    <AdvantageItemText>News Feed</AdvantageItemText>
                                </AdvantageItem>
                            </AdvantagesList>
                        </AdvantagesDiv>
                    </CardItem>
                    <CardItem style={{ border: '1px solid rgb(23,188,185)' }}>
                        {switchState && <CardLabel style={{color:'rgb(105,245,243)', border:'1px solid rgb(23,188,185)', background:'rgb(23,188,185)'}} >$60 SAVED</CardLabel>}
                        <CardButtonDiv>
                            <CardLevel style={{color:'rgb(23,188,185)'}}>PRO</CardLevel>
                            <CardDescription>For developers that are just getting started</CardDescription>
                            <CardButton style={{ color: 'rgb(23,188,185)', border: '1px solid rgb(23,188,185)' }}>Get started</CardButton>
                        </CardButtonDiv>
                        <PriceDiv>
                            <PriceText>{ switchState ? '$19.99' : '$24.99'}</PriceText>
                            <PriceDescription>{ switchState ? 'per month, billed annually' : 'per month'}</PriceDescription>
                        </PriceDiv>
                        <AdvantagesDiv>
                            <AdvantagesText>Everything in BASIC plus:</AdvantagesText>
                            <AdvantagesList>
                                <AdvantageItem>
                                    <TickIcon />
                                    <AdvantageItemText>Control Center</AdvantageItemText>
                                </AdvantageItem>
                                <AdvantageItem>
                                    <TickIcon />
                                    <AdvantageItemText>Multi-Trade Strategies</AdvantageItemText>
                                </AdvantageItem>
                                <AdvantageItem>
                                    <TickIcon />
                                    <AdvantageItemText>Custom-Trade Strategies</AdvantageItemText>
                                </AdvantageItem>
                                <AdvantageItem>
                                    <TickIcon />
                                    <AdvantageItemText>Copy Trading</AdvantageItemText>
                                </AdvantageItem>
                            </AdvantagesList>
                        </AdvantagesDiv>
                    </CardItem>
                    <CardItem style={{ border: '1px solid rgb(23,150,186)' }}>
                        <CardLabel style={{color:'rgb(105,245,243)', border:'1px solid rgb(23,150,186)', background:'rgb(23,150,186)'}} >MOST POPULAR</CardLabel>
                        <CardButtonDiv>
                            <CardLevel style={{color:'rgb(23,150,186)'}}>ENTERPRISE</CardLevel>
                            <CardDescription>For developers that are just getting started</CardDescription>
                            <ContactButton>
                                <ContactLogo />
                                <a style={{textDecoration:'none'}} href='mailto:contact@wayoblabs.com' ><ContactText>Contact Us</ContactText></a>
                            </ContactButton>
                        </CardButtonDiv>
                        <PriceDiv>
                            <PriceText>Custom</PriceText>
                            <PriceDescription>individual prices</PriceDescription>
                        </PriceDiv>
                        <AdvantagesDiv>
                            <AdvantagesText>Everything in PRO plus:</AdvantagesText>
                            <AdvantagesList>
                                <AdvantageItem>
                                    <TickIcon />
                                    <AdvantageItemText>Your own brand Strategy and Bot</AdvantageItemText>
                                </AdvantageItem>
                            </AdvantagesList>
                        </AdvantagesDiv>
                    </CardItem>
                </CardItemDiv>
            </MainContainer>
        </CardSectionDiv>
    );
}

export default CardSection;