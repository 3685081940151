import * as exports from './Navbar.elements';
import Dropdown from '../Dropdown/Dropdown';
import DropdownItem from '../Dropdown/DropdownItem';
import { useState } from 'react';
import { IoDocumentTextOutline } from "react-icons/io5";
import { MdOutlineCandlestickChart } from "react-icons/md";
import { GiNotebook } from "react-icons/gi";
import { FaPeopleGroup } from "react-icons/fa6";
import { FaRobot } from "react-icons/fa6";
import { FaTelegramPlane } from "react-icons/fa";
import { GreyScreen } from '../GreyScreen/GreyScreen';
import logo from '../../images/logo.png'
import { NavLink } from 'react-router-dom';
import introLogo from '../../images/introLogo.png';
import whitepaper from '../../documents/whitepaper.pdf'

const navAnimate = {
    initial: {
        y: -130,
        opacity: 0,
        transition: { duration: 0.4, delay:0.25 }
    },
    animate: {
        y: 0,
        opacity: 1,
        transition: { duration: 0.4, delay:0.25}
    },
    exit: {}
}

const Navbar = () => {
    const [currentDropdown, setCurrentDropdown] = useState(0);
    const [mobileClicked, setMobileClicked] = useState(false);

    const changeDropdown = (dropdownNumber) => {
        if (currentDropdown === dropdownNumber) setCurrentDropdown(0);
        else setCurrentDropdown(dropdownNumber);
    }

    const bgObj = {
        'background': '#9BF3A4',
        'background': 'linear-gradient(to right, #9BF3A4 0%, #5CE4B4 45%, #14BAB9 100%)',
        '-webkit-background-clip': 'text',
        '-webkit-text-fill-color': 'transparent',
    }

    return (
        <>
            <exports.NavbarDiv>
                <exports.NavbarContainer variants={navAnimate} initial='initial' whileInView='animate' exit='exit'>
                    <exports.LogoDiv>
                        <exports.LogoText><span style={bgObj}>WAYOB</span>LABS.</exports.LogoText>
                    </exports.LogoDiv>
                    <exports.NavMenu mobileClicked={mobileClicked}>
                        <exports.LogoMobileDiv><span style={bgObj}>WAYOB</span> LABS.</exports.LogoMobileDiv>
                        <exports.NavItem>
                            <NavLink to='/' style={{ textDecoration: 'none', color: 'white' }}><exports.NavItemText>HOME</exports.NavItemText></NavLink>
                        </exports.NavItem>
                        
                        <exports.NavItem onClick={() => changeDropdown(2)}>
                            <exports.NavItemText>ABOUT</exports.NavItemText>
                            <Dropdown currentDropdown={currentDropdown} dropdownId={2}>
                                <NavLink to='/about' style={{ textDecoration: 'none' }}><DropdownItem text={'OUR COMPANY'} description={'LEARN MORE ABOUT OUR BUSINESS'} icon={FaPeopleGroup} /></NavLink>
                                <a target='_blank' href={whitepaper} style={{ textDecoration: 'none' }}><DropdownItem text={'WHITEPAPER ↗'} description={'READ OUR WAYOB LABS WHITE PAPER'} icon={GiNotebook} /></a>
                                <a target='_blank' href='https://t.me/wayoblabspublic' style={{ textDecoration: 'none' }}><DropdownItem text={'TELEGRAM GROUP ↗'} description={'JOIN OUR TELEGRAM COMMUNITY'} icon={FaTelegramPlane} /></a>
                            </Dropdown>
                        </exports.NavItem>
                        


                        <a target='_blank' href='https://app.wayoblabs.com' style={{ textDecoration: 'none' }}><exports.WrapperButton><exports.AppButton style={{ paddingLeft:'40px', paddingRight:'40px' }}>LOGIN</exports.AppButton></exports.WrapperButton></a>
                    </exports.NavMenu>
                    <exports.MobileIcon onClick={() => setMobileClicked(!mobileClicked)} />
                </exports.NavbarContainer>
            </exports.NavbarDiv>
            <GreyScreen show={mobileClicked} onClick={() => setMobileClicked(!mobileClicked)} />
        </>
    )
}

export default Navbar;

/*
<exports.NavItem>
                            <NavLink to='/pricing' style={{ textDecoration: 'none', color: 'white' }}><exports.NavItemText>PRICING</exports.NavItemText></NavLink>
                        </exports.NavItem>
*/

/*
<exports.NavItem onClick={() => changeDropdown(1)} >
                            <NavLink to='/indicator' style={{ textDecoration: 'none', color: 'white' }}><exports.NavItemText>PRODUCTS</exports.NavItemText></NavLink>
                        </exports.NavItem>
*/