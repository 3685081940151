import styled from "styled-components";

export const GreyScreen = styled.div`
position:fixed;
background:#ffffff;
opacity:30%;
height:100vh;
width:100vw;
top:0px;
z-index:180;
display:${({show}) => show == true ? 'block' : 'none'};
`;