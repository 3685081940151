import styled from "styled-components";
import { MainContainer } from "../MainContainer/MainContainer";
import explainImage from '../../images/imageLanding.png'
import Reveal from "../AnimationComponents/Reveal";

const ExplainSectionDiv = styled.div`
color:white;
display:flex;
justify-content:center;
background:black;
z-index:100;
position:relative;
`;


const ExplainTitle = styled.p`
font-size:60px;
text-align:center;
font-weight:500;
color:white;

@media screen and (max-width:960px){
    font-size:40px;
}
`;

const ExplainImage = styled.img`
//padding-left:150px;
width:140%;
cursor:pointer;
transition:0.3s;
//background:rgb(64,67,75, 0.3);
border-radius:12px;

&:hover{
    transform:scale(1.05);
    transition:0.3s;
    //background:rgb(82,84,91, 0.4); 
}

@media screen and (max-width:960px){
    padding-left:0px;
}
`;

const ExplainImgDiv = styled.div`
display:flex;
width:100%;
justify-content:center;
align-items:center;
`;

const ExplainTextDiv = styled.div`
display:flex;
flex-direction:column;
gap:10px;
justify-content:center;
align-items:center;
`;

const ExplainText = styled.p`
font-size:60px;
font-weight:500;
color:rgb(255,255,255, 0.3);
padding:10px;
border-bottom:1px solid rgb(255,255,255, 0.3);
transition:0.4s;
text-align:center;
max-width:100%;

&:hover{
    color:rgb(56,172,131);
    transition:0.4s;
}

@media screen and (max-width:960px){
    font-size:40px;
}
`;

const ExplainSection = () => {
    const bgObj = {
        'background': '#9BF3A4',
        'background': 'linear-gradient(to right, #9BF3A4 0%, #5CE4B4 45%, #14BAB9 100%)',
        '-webkit-background-clip': 'text',
        '-webkit-text-fill-color': 'transparent',
        'fontWeight': '700',
    }

    return (
        <ExplainSectionDiv>
            <MainContainer style={{ flexDirection: 'column', gap: '60px', alignItems: 'center', background:'black' }}>
                <Reveal>
                    <ExplainTitle><span style={bgObj}>JOIN OUR COMMUNITY</span><br />AND BE ONE OF THE FIRST</ExplainTitle>
                </Reveal>
                <ExplainImgDiv>
                    <ExplainTextDiv>
                        <Reveal>
                            <ExplainText>CUSTOM AI CREATION</ExplainText>
                        </Reveal>
                        <Reveal>
                            <ExplainText>CUSTOM TRADING BOTS DEVELOPMENT</ExplainText>
                        </Reveal>
                        <Reveal>
                            <ExplainText>MULTIPLE EXCHANGES CONNECTED</ExplainText>
                        </Reveal>
                        <Reveal>
                            <ExplainText>BACKTESTING YOUR BOTS</ExplainText>
                        </Reveal>
                    </ExplainTextDiv>
                </ExplainImgDiv>
            </MainContainer>
        </ExplainSectionDiv>
    );
}

/*
<ExplainText>
                    Cryptocurrencies have garnered unprecedented growth and media attention in the past decade, but they still need wider adoption among the general public. Current trading solutions are very difficult to use for the general public. They were built either under the assumtion that the user already knows and is comfortable trading crypto; Or just for a small fraction of crypo users.
                    <br />
                    <span style={{fontWeight:800}}>We are here to change that.</span>
                    <br/>
                    <br />
                    Experience hassle-free crypto investing with our intuitive platform with ready to implement strategies and automated expert support like on traditional trading firms. Our services guarantee seamless strategy executions, allowing you to focus on growing your investments.
                </ExplainText>
*/

export default ExplainSection;