import styled from "styled-components";
import { keyframes } from "styled-components";

const animationIn = keyframes`
from {
    opacity: 0;
    transform: scale(0.8);
    transform-origin: top left;
  }
  to {
    opacity: 1;
    transform: scale(1);
    transform-origin: top left;
  }
`;

const animationOut = keyframes`
from {
    opacity: 1;
    transform: scale(1);
    transform-origin: top left;
  }
  to {
    opacity: 0;
    transform: scale(0.9);
    transform-origin: top left;
  }
`;

const animationMobileOut = keyframes`
from {
    opacity: 1;
    transform: translateX(0px);
  }
  to {
    opacity: 0;
    transform: translateX(-40px);
  }
`;

const animationMobileIn = keyframes`
from {
    opacity: 0;
    transform: translateX(-40px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
`;

const DropdownDiv = styled.div`
background:black;
border:1px solid #3b3d42;
position:absolute;
top:100px;
overflow:hidden;
border-radius:4px;
animation-name: ${({currentDropdown, dropdownId}) => currentDropdown == dropdownId ? animationIn : animationOut};
animation-duration: 190ms;
transform-origin:center center;
display:flex;
animation-timing-function: ease-in-out;
animation-fill-mode: forwards;
max-height: ${({ currentDropdown,  dropdownId}) => (currentDropdown == dropdownId ? '1000px' : '0px')};
transition:0.2s;
cursor:pointer;
z-index:200;

@media screen and (max-width:960px){
    position:static;
    margin-top:10px;
    animation-name: ${({currentDropdown, dropdownId}) => currentDropdown == dropdownId ? animationMobileIn : animationMobileOut};
}
`;

const DropdownContainer = styled.div`
padding:16px;

@media screen and (max-width:960px){
    padding:0px;
}
`;

const Dropdown = ({ currentDropdown, dropdownId, children }) => {
    return (
        <DropdownDiv currentDropdown={currentDropdown} dropdownId={dropdownId}>
            <DropdownContainer>
                {children}
            </DropdownContainer>
        </DropdownDiv>
    )
}

export default Dropdown;