const apiUrl = {
    api1: 'https://api.wayoblabs.com:2350',
    api2:'https://api2.wayoblabs.com/api',
    api3:'https://api3.wayoblabs.com'
}

export const postRequest = async (api, endpoint, body) => {
    let response = await fetch(apiUrl[api] + endpoint, {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('bearer'),
            "Content-Type": "application/json",
        },
    }).then(async (res) => { return { status: res.status, response: await res.json() } });

    return response;
}

export const deleteRequest = async (api, endpoint) => {
    let response = await fetch(apiUrl[api] + endpoint, {
        method: 'DELETE',
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('bearer'),
        }
    }).then(async (res) => { return { status: res.status, response: await res.json() } });

    return response;
}

export const fileRequest = async (api, endpoint, files, body) => {
    const formData = new FormData();
    files.forEach((file) => {
        formData.append('files', file);
    });

    for(let key in body){
        formData.append(key, body[key]);
    }

    let response = await fetch(apiUrl[api] + endpoint, {
        method: 'POST',
        body: formData,
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('bearer'),
        },
    }).then(async (res) => { return { status: res.status, response: await res.json() } });

    return response;
}