import { styled } from "styled-components";
import { MainContainer } from "../../components/MainContainer/MainContainer";
import predictImage from '../../images/predictImage.png';
import { keyframes } from "styled-components";

import 'aos/dist/aos.css'
import AOS from 'aos';
import { useEffect } from "react";

const circleAnimation = keyframes`
0%{
    transform:scale(1);
}
50%{
    transform:scale(1.1);
}
100%{
    transform:scale(1);
}
`;

const PredictSectionDiv = styled.div`
width:100%;
display:flex;
flex-direction:row;
justify-content:center;
align-items:center;
`;

const PredictTitleDiv = styled.div`
display:flex;
flex-direction:column;
justify-content:center;
align-items:center;
gap:20px;
`;

const PredictTitle = styled.p`
color:white;
font-weight:800;
font-size:53px;
text-align:center;
max-width:700px;
color:white;

@media screen and (max-width:960px){
    font-size:46px;
}
`;

const PredictSubtitle = styled.p`
max-width:1200px;
background: #9BF3A4;
background: linear-gradient(to right, #9BF3A4 0%, #5CE4B4 45%, #14BAB9 100%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
font-weight:600;
font-size:26px;
text-align:center;

@media screen and (max-width:960px){
    font-size:20px;
}
`;

const PredictImageDiv = styled.div`
width:70%;
border-radius:12px;
border:16px solid rgb(56,172,131, 0.1);
position:relative;

@media screen and (max-width:960px){
    width:100%;
}
`;

const PredictImage = styled.img`
width:100%;
position:relative;
`;

const CircleEffect = styled.div`
border:6px solid rgb(255, 255, 255, 0.7);
padding:5%;
border-radius:1000px;
position:absolute;
top:46%;
left:44%;
animation-name: ${circleAnimation};
animation-duration: 1200ms;
transform-origin:center center;
animation-timing-function: ease-in-out;
animation-fill-mode: forwards;
animation-iteration-count: infinite;
`;

const PredictSection = () => {

    useEffect(() => {
        AOS.init({ duration: 800 });
    }, []);

    return (
        <PredictSectionDiv>
            <MainContainer style={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center', maxWidth: '1500px', gap:'60px'}}>
                <PredictTitleDiv data-aos="zoom-out">
                    <PredictTitle>PREDICT PRICE MOVEMENT DIRECTION</PredictTitle>
                    <PredictSubtitle>Potential liquidation levels in a specific price range suggest likely price movement towards that zone. Use it to gauge the direction of price movement.</PredictSubtitle>
                </PredictTitleDiv>
                <PredictImageDiv data-aos="fade-up">
                    <PredictImage src={predictImage} />
                    <CircleEffect></CircleEffect>
                </PredictImageDiv>
            </MainContainer>
        </PredictSectionDiv>
    );
}

export default PredictSection;