export const bgObj = {
    'background': '#9BF3A4',
    'background': 'linear-gradient(to right, #9BF3A4 0%, #5CE4B4 45%, #14BAB9 100%)',
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent',
    'fontWeight':'700',
}

export const mainColors = {
    bgBox:'#0f0f0f',
    buttonColor:'#292929',
    greenColor:'#0ce6be',
    greenColor2:'#0d2723',
    boxBorder:'#252525',
}