import IntroSection from "../../components/IntroSection/IntroSection";
import AdvantagesSection from "../../components/AdvantagesSection/AdvantagesSection";
import SimpleSection from "../../components/SimpleSection/SimpleSection";
import ExplainSection from "../../components/ExplainSection/ExplainSection";
import SecondarySection from "../../components/SecondarySection/SecondarySection";
import ContactSection from "../../components/ContactSection/ContactSection";
import HorizontalScroll from "../../components/HorizontalScroll/HorizontalScroll";
import MainVideoComponent from "../../components/MainVideoComponent/MainVideoComponent";
import CountdownSection from "../../components/CountDownComponent/CountdownSection";
import { secondarySectionObject1, secondarySectionObject2 } from "./homepageObjects";

const HomePage = () => {
    return (
        <div>
            <div style={{ position: 'relative'}}>
                <IntroSection />
                <AdvantagesSection />
            </div>
            <ExplainSection />
            <MainVideoComponent />
            <SecondarySection {...secondarySectionObject2} />
            <SimpleSection />
            <ContactSection />
        </div>
    );
}

//<HorizontalScroll />

export default HomePage;