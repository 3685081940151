import { styled, keyframes } from "styled-components";
import { MainContainer } from "../MainContainer/MainContainer";
import { FaTelegramPlane } from "react-icons/fa";
import cubesBg from '../../images/cubesBg.png';
import { useRef } from 'react';
import Reveal from "../AnimationComponents/Reveal";

const BorderAnimation = keyframes`
0% {
    background-position: 0% 50%;
}
50% {
    background-position: 100% 50%;
}
100% {
    background-position: 0% 50%;
}
`;

const SecondarySectionDiv = styled.div`
width:100%;
display:flex;
flex-direction:row;
justify-content:center;
align-items:center;
position:relative;
background:black;
`;

const SecondarySectionTextDiv = styled.div`
display:flex;
flex-direction:column;
gap:27px;
width:50%;

@media screen and (max-width:960px){
    width:100%;
}
`;

const SecondarySectionTitle = styled.p`
color:white;
font-size:65px;
font-weight:500;

@media screen and (max-width:960px){
    font-size:40px;
}
`;

const SecondarySectionSubtitle = styled.p`
font-size:20px;
font-weight:400;
color:white;

@media screen and (max-width:960px){
    font-size:20px;
}
`;

const SecondarySectionButtonDiv = styled.div`
display:flex;
`;

const ButtonIcon = styled(FaTelegramPlane)`
font-size:24px;
`;

const SecondarySectionButton = styled.button`
padding: 20px;
display:flex;
gap:10px;
font-size:18px;
border-radius:60px;
border:none;
cursor:pointer;
background:#4DEAD0;
font-weight:600;
transition:0.2s;
color:black;

&:active{
    transform:scale(0.93);
 }

&:hover{
    background:rgb(253,252,254, 0.1);
    transition: 0.2s;
    color:white;
}
`;

const SecondaryImgDiv = styled.div`
display:flex;
justify-content:center;
position:relative;
transform-style:preserve-3d;
//border-radius:12px;
//border:16px solid rgb(56,172,131, 0.1);
width:50%;

@media screen and (max-width:960px){
    width:100%;
}

&::before{
    content: "";
    z-index: -1;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(33deg, #03E5B7 0%, #037ADE 100% );
    transform: translate3d(0px, 0px, 0) scale(0.2);
    filter: blur(60px);
    opacity: 40%;
    transition: opacity 0.3s;
    border-radius: inherit;
}
`;

const SecondarySectionImg = styled.img`
width:100%;
cursor:pointer;

@media screen and (max-width:960px){
    max-width:100%;
}
`;

const VideoContainer = styled.div`
display:flex;
justify-content:center;
position:relative;
transform-style:preserve-3d;
border-radius:12px;
--borderWidth: 6px;
position: relative;
border-radius: var(--borderWidth);


`;

//background: linear-gradient(60deg, #f79533, #f37055, #ef4e7b, #a166ab, #5073b8, #1098ad, #07b39b, #6fba82);

const VideoSource = styled.video`
width:${({ srcWidth }) => srcWidth};
//border-radius:12px;
//border:16px solid rgb(56,172,131, 0.1);
`;

const BackgroundDiv = styled.div`
position:absolute;
height:60%;
width:100%;
background:#001717;
z-index:1;
top:40px;
background-image:url(${cubesBg});
opacity:0.7;

@media screen and (max-width:960px){
    top:0px;
}
`;
const SecondarySection = ({ type, bg1, bg2, firstWord, title, subtitle, image, redirect }) => {

    const videoRef = useRef(undefined);

    const bgObj = {
        'background': '#9BF3A4',
        'background': 'linear-gradient(to right, #9BF3A4 0%, #5CE4B4 45%, #14BAB9 100%)',
        '-webkit-background-clip': 'text',
        '-webkit-text-fill-color': 'transparent',
        'fontWeight': '700',
    }

    return (
        <SecondarySectionDiv bg1={bg1} bg2={bg2}>
            <BackgroundDiv></BackgroundDiv>
            <MainContainer style={{ justifyContent: 'center', zIndex:2}} type={type} >
                <SecondaryImgDiv>
                    <Reveal>
                        <VideoContainer>
                            <VideoSource ref={videoRef} srcWidth={'60%'} playsInline autoPlay muted loop>
                                <source src={image} type='video/webm' />
                            </VideoSource>
                        </VideoContainer>
                    </Reveal>
                </SecondaryImgDiv>
                <SecondarySectionTextDiv>
                    <Reveal>
                        <SecondarySectionTitle><span style={bgObj}>{firstWord.toUpperCase()}</span> {title.toUpperCase()}</SecondarySectionTitle>
                    </Reveal>
                    <Reveal>
                        <SecondarySectionSubtitle>{subtitle.toUpperCase()}</SecondarySectionSubtitle>
                    </Reveal>
                </SecondarySectionTextDiv>
            </MainContainer>
        </SecondarySectionDiv >
    )
}

export default SecondarySection;

/*
&:after{
    content: '';
    position: absolute;
    //top: calc(-1 * var(--borderWidth));
    top:5%;
    //left: calc(-1 * var(--borderWidth));
    height: calc(87% + var(--borderWidth) * 2);
    width: calc(87% + var(--borderWidth) * 2);
    background: linear-gradient(60deg, #003128, #006c59, #00ad8e, #4bebd0, #c3fff5);
    border-radius: calc(2 * var(--borderWidth));
    z-index: -1;
    animation: ${BorderAnimation} 4s ease alternate infinite;
    background-size: 300% 300%;
}
*/

/*
<SecondarySectionButtonDiv>
                        <a style={{ textDecoration: 'none', color: 'black' }} target='_blank' href={redirect}><SecondarySectionButton>
                            <ButtonIcon />
                            LAUNCH BOT
                        </SecondarySectionButton>
                        </a>
                    </SecondarySectionButtonDiv>
*/