import { styled } from "styled-components";
import tensegrityLogo from '../../images/tensegrityLogo.png';
import affonso from '../../images/affonso.jpg';
import barbara from '../../images/barbara.jpg';
import sebastian from '../../images/sebastian.jpg';
import leo from '../../images/leo.jpg';
import { specialCharMap } from "@testing-library/user-event/dist/keyboard";
import Reveal from "../../components/AnimationComponents/Reveal";

const AboutPartnersDiv = styled.div`
width:100%;
display:flex;
flex-direction:column;
justify-content:center;
align-items:center;
gap:60px;
padding:20px;
padding-top:50px;
`;

const AboutPartnersTitle = styled.p`
font-size:60px;
font-weight:600;
//color:rgb(75,255,251);
background: #9BF3A4;
background: linear-gradient(to right, #9BF3A4 0%, #5CE4B4 45%, #14BAB9 100%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
`;

const PartnersDiv = styled.div`
display:flex;
justify-content:center;
align-items:center;
gap:40px;
//flex-wrap: wrap;
max-width:100%;
//-webkit-overflow-scrolling: touch;
//overflow:auto;
flex-wrap:wrap;
`;

const PartnerItem = styled.div`
display:flex;
flex-direction:column;
justify-content:center;
align-items:center;
gap:14px;
height:400px;
`;

const PartnerText = styled.p`
position:absolute;
top:0px;
height:100%;
transition:0.3s;
opacity:1;
padding:20px;
font-weight:500;
overflow:hidden;
border-radius:6px;
opacity:0;
font-size:18px;
`;

const PartnerImgDiv = styled.div`
width:260px;
//width:100%;
height:300px;
border-radius:6px;
border:3px solid rgb(31,192,185);
background:rgb(19,180,151, 0.4);
display:flex;
justify-content:center;
align-items:center;
position:relative;
cursor:pointer;
transition:0.2s;
overflow:hidden;

&:hover{
    transform:scale(1.1);
    transition:0.2s;
    border-color:rgb(15,145,151);
    ${PartnerText} {
        background:rgb(24,188,185, 0.7);
        transition:0.3s;
        opacity:1;
        color:white;
    }
}
`;

const PartnerImg = styled.img`
max-width:120%;
`;

const PartnerNameDiv = styled.div`
justify-content:center;
display:flex;
align-items:center;
flex-direction:column;
`;

const PartnerName = styled.p`
color:white;
font-weight:600;
font-size:20px;
`;

const PartnerPosition = styled.p`
background: #9BF3A4;
background: linear-gradient(to right, #9BF3A4 0%, #5CE4B4 45%, #14BAB9 100%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
`;



const AboutPartners = () => {
    return (
        <AboutPartnersDiv>
            <Reveal>
                <AboutPartnersTitle>OUR ADVISORS</AboutPartnersTitle>
            </Reveal>
            <PartnersDiv>
                <PartnerItem>
                    <Reveal>
                        <PartnerImgDiv>
                            <PartnerImg style={{ width: '110%' }} src={tensegrityLogo} />
                            <PartnerText>A global business boutique focused on innovation, investment and Web3 projects. Offer smart capital and strategic services to traditional and new, disruptive companies.</PartnerText>
                        </PartnerImgDiv>
                    </Reveal>
                    <PartnerNameDiv>
                        <Reveal>
                            <PartnerName>TENSEGRITY</PartnerName>
                        </Reveal>
                        <Reveal>
                            <PartnerPosition>WAYOB LABS TEAM ADVISOR</PartnerPosition>
                        </Reveal>
                    </PartnerNameDiv>
                </PartnerItem>
                <PartnerItem>
                    <Reveal>
                        <PartnerImgDiv>
                            <PartnerImg style={{ width: '100%', borderRadius: '6px' }} src={barbara} />
                            <PartnerText>Innovation C-Level specialist in foresight, business strategy and product creation, Speaker and Advisor on Innovation Boards.</PartnerText>
                        </PartnerImgDiv>
                    </Reveal>
                    <PartnerNameDiv>
                        <Reveal>
                            <PartnerName>Barbara Olivier</PartnerName>
                        </Reveal>
                        <Reveal>
                            <PartnerPosition>WAYOB LABS TEAM ADVISOR</PartnerPosition>
                        </Reveal>
                    </PartnerNameDiv>
                </PartnerItem>
                <PartnerItem>
                    <Reveal>
                        <PartnerImgDiv>
                            <PartnerImg style={{ width: '100%', borderRadius: '6px' }} src={sebastian} />
                            <PartnerText>Business Strategist, Investor, Speaker and board member in various Web3 companies.</PartnerText>
                        </PartnerImgDiv>
                    </Reveal>
                    <PartnerNameDiv>
                        <Reveal>
                            <PartnerName>SEBASTIAN GUERRERO</PartnerName>
                        </Reveal>
                        <Reveal>
                            <PartnerPosition>WAYOB LABS TEAM ADVISOR</PartnerPosition>
                        </Reveal>
                    </PartnerNameDiv>
                </PartnerItem>
                <PartnerItem>
                    <Reveal>
                        <PartnerImgDiv>
                            <PartnerImg style={{ width: '120%', borderRadius: '6px' }} src={leo} />
                            <PartnerText>C-Level Board Member specialist in company restructuring M&A processes, and digital transformation.</PartnerText>
                        </PartnerImgDiv>
                    </Reveal>
                    <PartnerNameDiv>
                        <Reveal>
                            <PartnerName>Leonardo Bar</PartnerName>
                        </Reveal>
                        <Reveal>
                            <PartnerPosition>WAYOB LABS TEAM ADVISOR</PartnerPosition>
                        </Reveal>
                    </PartnerNameDiv>
                </PartnerItem>
                <PartnerItem>
                    <Reveal>
                        <PartnerImgDiv>
                            <PartnerImg style={{ width: '100%', borderRadius: '6px' }} src={affonso} />
                            <PartnerText>Partner at Dux Capital with 20+ years of experience in financial institutions such as Guide Investimentos, Simplific Pavarini DVTM, BTG Pactual and CCF Bank.</PartnerText>
                        </PartnerImgDiv>
                    </Reveal>
                    <PartnerNameDiv>
                        <Reveal>
                            <PartnerName>AFFONSO MAYRINCK</PartnerName>
                        </Reveal>
                        <Reveal>
                            <PartnerPosition>WAYOB LABS TEAM ADVISOR</PartnerPosition>
                        </Reveal>
                    </PartnerNameDiv>
                </PartnerItem>
            </PartnersDiv>
        </AboutPartnersDiv>
    );
}

export default AboutPartners;