import image2 from '../../images/image2.png'
import image1 from '../../images/image1.png'
import comp1 from '../../videos/comp1.webm'
import comp2 from '../../videos/comp2.webm'

export const secondarySectionObject1 = {
    type:'row-reverse',
    bg1:'rgb(40,43,50)',
    bg2:'linear-gradient(180deg, rgba(40,43,50,1) 0%, rgba(32,34,39,1) 97%)',
    firstWord:'EASY',
    title:'TO USE',
    subtitle:'OUR PLATFORM PROVIDES AN EASY-TO-USE TRADING ENVIRONMENT THAT IS ADAPTIVE TO YOUR KNOWLEDGE LEVEL. WE REMOVE BARRIERS WITH ACCESIBLE INTERFACES AND CONVERSATIONAL AI TOOLS, ENSURING A USER-FRIENDLY EXPERIENCE.',
    image:comp1,
    redirect:'https://t.me/wayob_ai_bot'
}

export const secondarySectionObject2 = {
    type:'row',
    bg1:'#202227',
    bg2:'#202227',
    firstWord:'Grow',
    title:'YOUR CRYPTO',
    subtitle:'OUR TRADING INFRAESTRUCTURE ALLOWS USERS TO MANAGE THEIR INVESTMENT STRATEGIES. IT SERVES AS A HUB FOR VISUALIZING AND MANAGING INVESTMENTS AND EXPLORING DIFFERENT MACRO STRATEGIES SUCH AS LIQUIDITY ZONES AND HIGH FREQUENCY TRADING. THE PLATFORM PROVIDES A REAL-TIME INFORMATION ON THE LATEST MARKET DEVELOPMENTS, TRENDS, AND INSIGHTS.',
    image:comp2,
    redirect:'https://t.me/wayob_investment_bot',
}

