import {postRequest} from '../../utils/globalFunctions';

export const postBotMessage = async (message, mode) => {
    const body = {
        mode:mode,
        message:message,
    }

    const resp = await postRequest('api3', '/ai', body);
    return resp;
}