import styled from "styled-components";
import { mainColors } from "../../utils/globalObjects";
import { FaCaretDown } from "react-icons/fa";

export const ChatContainer = styled.div`
width:380px;
height:600px;
background:${mainColors.boxBorder};
position:fixed;
right:0px;
bottom:0px;
margin:10px;
border-radius:6px;
display:${({showChat}) => showChat ? 'flex' : 'none'};
flex-direction:column;
z-index:1000;

@media screen and (max-width:960px){
    margin:0px;
}
`;

export const ChatHeader = styled.div`
width:100%;
height:80px;
background:${mainColors.greenColor};
border-top-left-radius:6px;
border-top-right-radius:6px;
display:flex;
justify-content:center;
align-items:center;
`;

export const ChatTitleDiv = styled.div`
display:flex;
justify-content:space-between;
align-items:center;
gap:30px;
`;

export const ChatTitle = styled.p`
font-weight:700;
font-size:22px;
`;

export const CloseButton = styled(FaCaretDown)`
font-size:22px;
cursor:pointer;
`;

export const ChatBody = styled.div`
width:100%;
height:100%;
display:flex;
flex-direction:column;
padding:20px;
gap:10px;
overflow-y:scroll;
`;

export const ChatSend = styled.div`
width:100%;
height:60px;
background:${mainColors.buttonColor};
display:flex;
justify-content:center;
`;

export const ChatModeButton = styled.p`
width:25%;
display:flex;
justify-content:center;
align-items:center;
color:white;
cursor:pointer;
border:1px solid transparent;

&:hover{
    border-color:${mainColors.greenColor};
}
`;

export const ChatInput = styled.input`
width:60%;
border:1px solid ${mainColors.greenColor};
background:${mainColors.bgBox};
color:white;
padding:5px;
`;

export const ChatSendButton = styled.button`
width:15%;
cursor:pointer;
color:white;
background:${mainColors.buttonColor};
border:1px solid ${mainColors.boxBorder};
transition:0.2s;

&:active{
    transform:scale(0.9);
}

&:hover{
    color:${mainColors.greenColor};
    border-color:${mainColors.greenColor};
    transition:0.2s;
}
`;

export const ChatUserMsg = styled.div`
background:${mainColors.greenColor2};
color:white;
padding:6px;
border-radius:9px;
font-size:13px;
`;

export const ChatBotMsg = styled.div`
background:${mainColors.bgBox};
color:white;
padding:6px;
border-radius:9px;
font-size:13px;
`;

export const ChatMsgDiv = styled.div`
width:100%;
align-items:center;
justify-content:start;
display:flex;
`;

export const ChatModeDiv = styled.div`
position:fixed;
bottom:60px;
width:100%;
background:${mainColors.bgBox};
display:${({show}) => show ? 'flex' : 'none'};
color:white;
border-top:1px solid ${mainColors.greenColor};
flex-direction:column;
`;

export const ChatModeItem = styled.p`
cursor: pointer;
padding:20px;
transition:0.2s;

&:hover{
    color:${mainColors.greenColor};
    background:${mainColors.greenColor2};
    transition:0.2s;
}
`;