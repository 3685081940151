import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Reveal from "../AnimationComponents/Reveal";

const CountdownDiv = styled.div`
display:flex;
flex-direction:column;
justify-content:center;
gap:20px;
align-items:center;
`;

const CountdownText = styled.p`
font-size:18px;
color:white;
`;

const TimerDiv = styled.div`
display:flex;
gap:20px;
`;

const TimerItem = styled.div`
display:flex;
flex-direction:column;
gap:0px;
padding:10px;
width:80px;
border:1px solid white;
background:black;
border-radius:8px;
justify-content:center;
align-items:center;

@media screen and (max-width:960px){
    width:60px;
}
`;

const TimerNumber = styled.p`
color:white;
font-size:26px;

@media screen and (max-width:960px){
    font-size:18px;
}
`;

const TimerLabel = styled.p`
color:white;
font-weight:600;
`;

const CountdownComponent = () => {
    const setCurrentTimer = () => {
        const actualDate = new Date();
        const betaDate = new Date('2024-06-25T00:00:00.000Z')

        const newTimer = betaDate.getTime() - actualDate.getTime();

        return { time: newTimer, days: 0, hours: 0, minutes: 0, seconds: 0 }
    }

    const [timer, setTimer] = useState(setCurrentTimer());
    const id = useRef(null);

    const clear = () => {
        window.clearInterval(id.current);
    }



    const handleTimer = () => {
        setTimer((prev) => {
            const total_seconds = Math.floor(prev.time / 1000);
            const total_minutes = Math.floor(total_seconds / 60);
            const total_hours = Math.floor(total_minutes / 60);
            const days = Math.floor(total_hours / 24);

            const seconds = total_seconds % 60;
            const minutes = total_minutes % 60;
            const hours = total_hours % 24;
            return { time: prev.time - 1000, days: days, hours: hours, minutes: minutes, seconds: seconds, };
        });
    }

    useEffect(() => {
        id.current = window.setInterval(() => {
            handleTimer();
        }, 1000)

        return () => clear();
    }, []);

    return (
        <CountdownDiv>
            <Reveal>
                <CountdownText>BETA TEST COUNTDOWN</CountdownText>
            </Reveal>
            <Reveal>
                <TimerDiv>
                    <TimerItem>
                        <TimerNumber>{0}</TimerNumber>
                        <TimerLabel>DAY</TimerLabel>
                    </TimerItem>
                    <TimerItem>
                        <TimerNumber>{0}</TimerNumber>
                        <TimerLabel>HOUR</TimerLabel>
                    </TimerItem>
                    <TimerItem>
                        <TimerNumber>{0}</TimerNumber>
                        <TimerLabel>MIN</TimerLabel>
                    </TimerItem>
                    <TimerItem>
                        <TimerNumber>{0}</TimerNumber>
                        <TimerLabel>SEC</TimerLabel>
                    </TimerItem>
                </TimerDiv>
            </Reveal>
        </CountdownDiv>
    )
};

export default CountdownComponent;