import styled from "styled-components";
import { MainContainer } from "../MainContainer/MainContainer";
import bgSimple from '../../images/backgroundSimple.png'
import Reveal from "../AnimationComponents/Reveal";
import animatedCubes from '../../images/animatedCubes.mp4'

const SimpleSectionDiv = styled.div`
display:flex;
justify-content:center;
max-width:100%;
position:relative;
//background-image:url(${bgSimple});
padding-top:50px;
padding-bottom:50px;
overflow:hidden;
`;

const SimpleSectionTitle = styled.p`
color:white;
font-size:120px;
font-weight:500;
max-width:100%;
text-align:center;

@media screen and (max-width:960px){
    font-size:60px;
}
`;

const SimpleSectionSubtitle = styled.p`
font-size:26px;
max-width:700px;
text-align:center;
color:white;
font-weight:500;
`;

const SimpleSectionButton = styled.button`
padding:20px;
width:30%;
background:#4DEAD0;
border-radius:60px;
border:none;
color:black;
font-size:24px;
font-weight:600;
cursor:pointer;
transition:0.2s;

&:active{
    transform:scale(0.93);
 }

&:hover{
    background:rgb(253,252,254, 0.1);
    transition: 0.2s;
    color:white;
}

@media screen and (max-width:960px){
    width:100%;
    font-size:14px;
}
`;

const SimpleSectionTitleDiv = styled.div`
display:flex;
flex-direction:column;
`;

export const BgSimple = styled.img`
width:100%;
position:absolute;
top:0px;
right:0px;
z-index:-1;
opacity:100%;

@media screen and (max-width:960px){
    justify-content:center;
    display:flex;
    width:300%;
}
`;

const SimpleSection = () => {
    const bgObj = {
        'background': '#9BF3A4',
        'background': 'linear-gradient(to right, #9BF3A4 0%, #5CE4B4 45%, #14BAB9 100%)',
        '-webkit-background-clip': 'text',
        '-webkit-text-fill-color': 'transparent',
        'fontWeight': '700',
    }
    return (
        <SimpleSectionDiv>
            <MainContainer style={{ justifyContent: 'center', flexDirection: 'column', alignItems: 'center', gap: '30px' }}>
                <SimpleSectionTitleDiv>
                    <Reveal>
                        <SimpleSectionTitle style={bgObj}>CUTTING EDGE</SimpleSectionTitle>
                    </Reveal>
                    <Reveal>
                        <SimpleSectionTitle>TRADING TOOLS</SimpleSectionTitle>
                    </Reveal>
                </SimpleSectionTitleDiv>
                <Reveal>
                    <SimpleSectionSubtitle>INTUITIVELY DESIGNED CRYPTOCURRENCY TRADING TOOLS, PRIORITIZING SEAMLESS USABILITY.</SimpleSectionSubtitle>
                </Reveal>
            </MainContainer>
            <video autoPlay muted loop id="myVideo" style={{position:'absolute', right:'-140px', bottom:'0', minWidth:'120%', minHeight:'100%', zIndex:-1, overflow:'hidden'}}>
                <source src={animatedCubes} type="video/mp4" />
            </video>
        </SimpleSectionDiv>
    );
}

export default SimpleSection;