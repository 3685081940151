import { styled } from "styled-components";
import team1 from '../../images/team1.png'
import team2 from '../../images/team2.png'
import team3 from '../../images/team3.png'
import team4 from '../../images/team4.png'
import { FaLinkedin } from "react-icons/fa";
import { FaTwitterSquare } from "react-icons/fa";
import Reveal from "../../components/AnimationComponents/Reveal";

const AboutTeamDiv = styled.div`
width:100%;
display:flex;
flex-direction:column;
justify-content:center;
align-items:center;
gap:60px;
padding:20px;
`;

const AboutTeamTitle = styled.p`
font-size:60px;
font-weight:600;
//color:rgb(75,255,251);
background: #9BF3A4;
background: linear-gradient(to right, #9BF3A4 0%, #5CE4B4 45%, #14BAB9 100%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
`;


const TeamDiv = styled.div`
display:flex;
gap:40px;
flex-wrap:wrap;
justify-content: center;
align-items: center;
`;

const TeamText = styled.p`
position:absolute;
top:0px;
height:100%;
transition:0.3s;
opacity:0;
padding:20px;
font-weight:500;
overflow:hidden;
border-radius:6px;
`;

const TeamItem = styled.div`
display:flex;
flex-direction:column;
gap:14px;
justify-content: center;
align-items:center;
`;

const TeamImgDiv = styled.div`
border-radius:6px;
max-width:225px;
position:relative;
background:rgb(31,192,185);
cursor:pointer;
transition:0.2s;
align-items:center;
padding:3px;
display:flex;
align-items:center;

&:hover{
    transform:scale(1.1);
    transition:0.2s;
    border-color:rgb(15,145,151);
    ${TeamText} {
        background:rgb(24,188,185, 0.7);
        transition:0.3s;
        opacity:1;
        color:white;
    }
}
`;

const TeamImg = styled.img`
max-width:100%;
border-radius:5px;
`;

const TeamNameDiv = styled.div`
display:flex;
flex-direction:column;
justify-content:center;
align-items:center;
`;

const TeamName = styled.p`
color:white;
font-weight:600;
font-size:24px;
`;

const TeamPosition = styled.p`
background: #9BF3A4;
background: linear-gradient(to right, #9BF3A4 0%, #5CE4B4 45%, #14BAB9 100%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
`;

const LinkedinIcon = styled(FaLinkedin)`
font-size:32px;
background: #9BF3A4;
background: linear-gradient(to right, #9BF3A4 0%, #5CE4B4 45%, #14BAB9 100%);
border-radius:6px;
cursor:pointer;

&:hover{
    background: #9BF3A4;
    background: linear-gradient(to right, #9BF3A4 100%, #5CE4B4 100%, #14BAB9 100%);
}
`;

const TwitterIcon = styled(FaTwitterSquare)`
font-size:32px;
background: #9BF3A4;
background: linear-gradient(to right, #9BF3A4 0%, #5CE4B4 45%, #14BAB9 100%);
border-radius:6px;
cursor:pointer;

&:hover{
    background: #9BF3A4;
    background: linear-gradient(to right, #9BF3A4 100%, #5CE4B4 100%, #14BAB9 100%);
}
`;

const TeamIconsDiv = styled.div`
display:flex;
justify-content:center;
align-items:center;
gap:20px;
`;

const AboutTeam = () => {
    return (
        <AboutTeamDiv>
            <Reveal>
                <AboutTeamTitle>THE WAYOBS</AboutTeamTitle>
            </Reveal>
            <TeamDiv>
                <TeamItem>
                    <Reveal>
                        <TeamImgDiv>
                            <TeamImg src={team1} />
                            <TeamText>Skilled technical leader and expert in robotics and industrial automation. Carlos has experience from finance to blockchain an has contributed to projects such as Hathor and Solana.</TeamText>
                        </TeamImgDiv>
                    </Reveal>
                    <TeamNameDiv>
                        <Reveal>
                            <TeamName>CARLOS BLASCO</TeamName>
                        </Reveal>
                        <Reveal>
                            <TeamPosition>CO-FOUNDER AND CEO</TeamPosition>
                        </Reveal>
                    </TeamNameDiv>
                    <TeamIconsDiv>
                        <a target='_blank' href='https://www.linkedin.com/in/carlos-blasco-bueno-701a95245?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app' style={{ textDecoration: 'none', color: 'inherit' }}><LinkedinIcon /></a>
                    </TeamIconsDiv>
                </TeamItem>
                <Reveal>
                    <TeamItem>
                        <Reveal>
                            <TeamImgDiv>
                                <TeamImg src={team2} />
                                <TeamText>Computer Engineer specialized in CyberSecurity. Axel brings a meticulous approach and deep knowledge of cyber threats. Boasts over 4+ years of extensive expertise in full-stack development and blockchain technology."</TeamText>
                            </TeamImgDiv>
                        </Reveal>
                        <TeamNameDiv>
                            <Reveal>
                                <TeamName>AXEL MAISONNEUVE</TeamName>
                            </Reveal>
                            <Reveal>
                                <TeamPosition>CO-FOUNDER AND COO</TeamPosition>
                            </Reveal>
                        </TeamNameDiv>
                        <TeamIconsDiv>
                            <LinkedinIcon />
                        </TeamIconsDiv>
                    </TeamItem>
                </Reveal>
                <Reveal>
                    <TeamItem>
                        <Reveal>
                            <TeamImgDiv>
                                <TeamImg src={team3} />
                                <TeamText>Electrical Engineer specialized in Big Data and Blockchain. he takes the lead in managing and analysing large datasets, and his experience ensures that our projects fully leverage the power of information.</TeamText>
                            </TeamImgDiv>
                        </Reveal>
                        <TeamNameDiv>
                            <Reveal>
                                <TeamName>DAVID GARCÍA</TeamName>
                            </Reveal>
                            <Reveal>
                                <TeamPosition>CO-FOUNDER AND CIO</TeamPosition>
                            </Reveal>
                        </TeamNameDiv>
                        <TeamIconsDiv>
                            <a target='_blank' href='https://www.linkedin.com/in/david-garc%C3%ADa-tercero-90b589131?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app' style={{ textDecoration: 'none', color: 'inherit' }}><LinkedinIcon /></a>
                        </TeamIconsDiv>
                    </TeamItem>
                </Reveal>
                <Reveal>
                    <TeamItem>
                        <Reveal>
                            <TeamImgDiv>
                                <TeamImg src={team4} />
                                <TeamText>Software Engineer and Mathematician specializing in AI and data mining. Known for an analytical approach that extracts valuable insights from complex datasets, elevating our products to the forefront.</TeamText>
                            </TeamImgDiv>
                        </Reveal>
                        <TeamNameDiv>
                            <Reveal>
                                <TeamName>JAIME MORILLO</TeamName>
                            </Reveal>
                            <Reveal>
                                <TeamPosition>CO-FOUNDER AND CTO</TeamPosition>
                            </Reveal>
                        </TeamNameDiv>
                        <TeamIconsDiv>
                            <a target='_blank' href='https://www.linkedin.com/in/jaimemorillo?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app' style={{ textDecoration: 'none', color: 'inherit' }}><LinkedinIcon /></a>
                        </TeamIconsDiv>
                    </TeamItem>
                </Reveal>
            </TeamDiv>
        </AboutTeamDiv>
    );
}
//
export default AboutTeam;